//---------------------------------------------------------//
@import 'normalize'; /* site wide variables (colours, font sizes) */
@import 'variables'; /* site wide variables (colours, fonts) */
@import 'toolkit'; /* mixin (@include font-size(??); , @include svg_img("../images/??");) and common classes (.clearfix, .img-replacment) */
@import 'general-tags-and-classes';
@import 'grid'; /* adapted boostap 4 grid*/
@import 'header';
@import 'footer';
@import 'pagination';
//@import 'breadcrumb';
@import 'social';
@import 'buttons';
@import 'forms';
@import 'swiper';
@import 'fancybox';
//@import 'tabs';

.waypoint{
	transition: .2s;
	opacity: 0;
	visibility: hidden;
	transform: translateY(5.0rem);
	will-change: transform;

	&.animate{
		opacity: 1;
		visibility: visible;
		transform: translateY(0);
		transition: .5s .1s;
	}
}

//
//!!BLOCKS START
//

.main-wrapper{

	.home &{
		font-size: 0;
	}
}

.side-slide-con{
	font-size: 0;
	background-color: $grey;

	[data-scroll-section]{
		opacity: 1!important;
		pointer-events: auto!important;
		background-color: white;

	}

	@media (max-width: 1366px) and (min-width: 1024px) and (min-height: 767px) and (min-aspect-ratio: 4/3) and (hover: none){
		width: 100%;
		height: auto!important;
		display: block!important;

		[data-scroll-section]{
			transform: none!important;
			display: block!important;
			opacity: 1!important;
			height: auto!important;
			pointer-events: auto!important;

			.row{
				min-height: 0!important;
			}

			*:not(i){
				transform: none!important;
			}
		}
	}

	@media (max-width: 1024px){
		width: 100%;
		height: auto!important;
		display: block!important;

		[data-scroll-section]{
			transform: none!important;
			display: block!important;
			opacity: 1!important;
			height: auto!important;
			pointer-events: auto!important;

			.row{
				min-height: 0!important;
			}

			*:not(i){
				transform: none!important;
			}
		}
	}

	*{
		white-space: normal;
	}

	.hero-section{
		width: 100%;
	}

	.row{
		min-height: 100vh;
	}

	.hero{
		height: 100vh;
		width: 100vw;
		background-color: red;
	}

	.filler{
		height: 100vh;
		width: 100vw;
		background-color: green;
		max-width: none;

		&:nth-of-type(3){
			background-color: orange;
			width: 75vw;
		}

		&:nth-of-type(4){
			background-color: blue;
			width: 150vw;
		}

		&:nth-of-type(5){
			background-color: pink;
			width: 25vw;
		}
	}
}

.hero-section{

	&:before{
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: rgba(#0A0A0A, .4);
	}

	.container{
		position: relative;
		z-index: 2;
		max-width: 152.3rem;

		@media (max-width: $xl){
			max-width: 150.8rem;
		}
	}

	.row.row{
		align-items: center;
		padding-top: 30rem;
		padding-bottom: 30rem;

		@media (max-width: 1366px) and (min-width: 1024px) and (min-height: 767px) and (min-aspect-ratio: 4/3) and (hover: none){
			min-height: 823px!important;
		}

		@media (max-width: 1024px){
			min-height: 823px!important;
		}

		@media (max-width: $md){
			padding-top: 6rem;
			padding-bottom: 6rem;
			// min-height: 88rem!important;
			min-height: 100vh !important;
		}

		@media (max-width: $md){
			flex-direction: column;
			justify-content: center;
		}
	}

	*{
		color: $white;
	}

	.logo-con{
		
		@media (min-width: $md + 1){
			flex: 0 0 42.83%;
			max-width: 42.83%;
		}

		@media (max-width: 1366px) and (min-width: 1024px) and (min-height: 767px) and (min-aspect-ratio: 4/3) and (hover: none){
			flex: 0 0 100%;
			max-width: 100%;
		}

		@media (max-width: 1024px){
			flex: 0 0 100%;
			max-width: 100%;
		}

		img{
			width: 22.4rem;

			@media (max-width: $xl){
				width: 24.3rem;
			}

			@media (max-width: 1366px) and (min-width: 1024px) and (min-height: 767px) and (min-aspect-ratio: 4/3) and (hover: none){
				margin: 0 auto;
				width: 280px;
			}

			@media (max-width: 1024px){
				margin: 0 auto;
				width: 280px;
			}

			@media (max-width: $md){
				width: 14.9rem;
				margin-bottom: 2.1rem;
			}
		}
	}

	.text-con{
		
		@media (min-width: $md + 1){
			flex: 0 0 57.17%;
			max-width: 57.17%;
		}

		@media (min-width: 1366px + 1){
			padding-left: 11rem;
		}

		@media (max-width: $xl){
			
			h1{
				font-size: 8.7rem;
			}
		}

		@media (max-width: 1366px) and (min-width: 1024px) and (min-height: 767px) and (min-aspect-ratio: 4/3) and (hover: none){
			text-align: center;
			flex: 0 0 100%;
			max-width: 100%;

			h1{
				font-size: 48px;
			}
		}

		@media (max-width: 1024px){
			text-align: center;
			flex: 0 0 100%;
			max-width: 100%;

			h1{
				font-size: 48px;
			}
		}

		@media (max-width: $md){
			
			h1{
				@include font-size(32);
			}
		}
	}

	.hero-menu-bottom{
		position: absolute;
		bottom: 4.1rem;
   		left: -2rem;

		@media (max-width: 1366px) and (min-width: 1024px) and (min-height: 767px) and (min-aspect-ratio: 4/3) and (hover: none){
			display: none;
		}

		@media (max-width: 1024px){
			display: none;
		}

		.hero-menu-con{
			width: 26.468rem;

			&.open{

				ul.hero-menu{
					max-height: 20rem;
					padding-bottom: .8rem;
				}

				.menu{

					i{

						&::after{
							transform: translateY(-50%) rotate(0deg);
						}
					}
				}
			}
		}

		ul.hero-menu{
			max-height: 0;
			overflow: hidden;
			border-bottom: .1rem solid $white;
			margin-bottom: .5rem;
			@include font-size(12);
			font-size: clamp(10px , 1.2rem, 1.2rem);
			padding-bottom: 0rem;
			transition: $time;

			.menu-item-11{
				display: none;
			}

			li{
				@include font-size(12);
				font-size: clamp(10px , 1.2rem, 1.2rem);
				margin-bottom: 1.1rem;
				font-weight: 400;

				@media (max-width: $xl){
					margin-bottom: 13px;
				}

				&.menu-item-224{
					display: none;
				}

				a{
					@include font-size(12);
					font-size: clamp(10px , 1.2rem, 1.2rem);
					letter-spacing: .3em;
					text-transform: uppercase;
					text-decoration: none;
					font-weight: 400;

					&:hover,
					&:focus-visible{
						color: $blue;
					}
				}
			}
		}

		.menu{
			padding: 5px 0;
			text-transform: uppercase;
			@include font-size(12);
			font-size: clamp(10px , 1.2rem, 1.2rem);
			letter-spacing: .3em;
			position: relative;
			cursor: pointer;
			padding-right: clamp(21px , 2.1rem, 2.1rem);
			display: inline-block;

			&:hover,
			&:focus-visible{
				
				&:after{
					max-width: 100%;
				}
			}

			&:after{
				content: '';
				position: absolute;
				bottom: 0;
				left: 0;
				width: 100%;
				max-width: 0;
				height: .04rem;
				background-color: currentColor;
				transition: $time;
			}
			
			i{
				position: absolute;
				top: 50%;
				right: 0;
				width: clamp(9px , 1rem, 1rem);
				height: clamp(9px , 1rem, 1rem);
				transform: translateY(-50%);

				&::before,
				&::after{
					content: '';
					position: absolute;
					top: 40%;
					right: 0;
					width: clamp(9px , 1rem, 1rem);
					height: .1rem;
					background-color: currentColor;
					transition: $time;
				}

				&::before{
					transform: translateY(-50%) rotate(0deg);
				}

				&::after{
					transform: translateY(-50%) rotate(90deg);
				}
			}
		}
	}
}

.link{
	padding: .5rem 0;
	text-transform: uppercase;
	@include font-size(12);
	font-size: clamp(12px , 1.2rem, 1.2rem);
	letter-spacing: .3em;
	position: relative;
	cursor: pointer;
	padding-right: clamp(25px , 2.5rem, 2.5rem);
	display: inline-block;
	text-decoration: none;

	@media (max-width: $md){
		font-size: 12px;
		padding-right: 25px;
	}

	&:hover,
	&:focus-visible{
		color: currentColor;
		
		&:after{
			max-width: 100%;
		}
	}

	&:after{
		content: '';
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		max-width: 0;
		height: .04rem;
		background-color: currentColor;
		transition: $time;
	}
	
	i{
		position: absolute;
		top: 50%;
		right: 0;
		width: clamp(9px , 1rem, 1rem);
		height: clamp(9px , 1rem, 1rem);
		transform: translateY(-50%);

		@media (max-width: $md){
			width: 10px;
			height: 10px;
		}

		&::before,
		&::after{
			content: '';
			position: absolute;
			top: 40%;
			right: 0;
			width: clamp(9px , 1rem, 1rem);
			height: .4px;
			background-color: currentColor;
			transition: $time;

			@media (max-width: $md){
				width: 10px;
			}
		}

		&::before{
			transform: translateY(-50%) rotate(0deg);
		}

		&::after{
			transform: translateY(-50%) rotate(90deg);
		}
	}
}

.home-our-development-section{

	@media (min-width: 1024px + 1){
		display: inline-flex!important;
		max-width: none;
	}

	@media (max-width: 1366px) and (min-width: 1024px) and (min-height: 767px) and (min-aspect-ratio: 4/3) and (hover: none){
		h3 {
			padding-top:0;
			margin-bottom: 2rem;
		}
	}

	@media (max-width: 1024px){
		h3 {
			padding-top:0;
			margin-bottom: 2rem;
		}
	}

	.container{

		&.left{
			
			@media (min-width: $md + 1){
				min-width: 88.45rem;
				min-width: 884.5px;
				max-width: none;
				border-right: .1rem solid rgba($color: #191E2A, $alpha: 0.5);
			}

			.row{
				padding-top: 7rem;

				@media (max-width: 1366px) and (min-width: 1024px) and (min-height: 767px) and (min-aspect-ratio: 4/3) and (hover: none){
					padding-top: 102px;
				}

				@media (max-width: 1024px){
					padding-top: 102px;
				}

				@media (max-width: $md){
					padding-top: 6.1rem;
				}
			}

			.col-12{
				background-size: 86.0095rem auto;
				background-image: url(../images/our-back.svg);
				background-repeat: no-repeat;
				background-position: right -7.4rem bottom -26.4rem;

				@media (max-width: $xl){
					background-position: right -8.4rem bottom -26.4rem;
				}

				@media (min-width: $md + 1){
					padding-left: 36rem;
				}

				@media (min-width: $xl + 1){
					padding-left: 40rem;
				}

				@media (max-width: 1366px) and (min-width: 1024px) and (min-height: 767px) and (min-aspect-ratio: 4/3) and (hover: none){
					padding-left: 0;
					padding-bottom: 108rem;
					background-size: 140% auto;
					background-position: right 11.8% bottom -87%;
					border-bottom: .4px solid $grey-dark;
				}
				
				@media (max-width: 1024px){
					padding-left: 0;
					padding-bottom: 108rem;
					background-size: 140% auto;
					background-position: right 11.8% bottom -87%;
					border-bottom: .4px solid $grey-dark;
				}

				@media (max-width: $md){
					padding-bottom: 21rem;
				}
				
				img{
					width: 26.5rem;
					margin-bottom: 24rem;

					@media (max-width: $xl){
						margin-bottom: 23rem;
					}

					@media (max-width: 1366px) and (min-width: 1024px) and (min-height: 767px) and (min-aspect-ratio: 4/3) and (hover: none){
						width: 68.5%;
					}

					@media (max-width: 1024px){
						width: 68.5%;
					}

					@media (max-width: $md){
						width: 71%;
						margin-bottom: 4rem;
					}
				}

				.text{
					width: 36rem;

					@media (max-width: $xl){
						width: 40rem;
					}

					@media (max-width: 1366px) and (min-width: 1024px) and (min-height: 767px) and (min-aspect-ratio: 4/3) and (hover: none){
						padding-left: 7.3rem;
						width: 100%;
					}

					@media (max-width: 1024px){
						padding-left: 7.3rem;
						width: 100%;
					}

					@media (max-width: $md){
						padding-left: 1.5rem;
					}
				}

				h6{
					margin-bottom: 7.25rem;

					@media (max-width: $xl){
						margin-bottom: 6.55rem;
					}

					@media (min-width: $md + 1){
						padding-left: .2rem;
					}

					@media (max-width: 1366px) and (min-width: 1024px) and (min-height: 767px) and (min-aspect-ratio: 4/3) and (hover: none){
						margin-bottom: 34.9rem;
					}

					@media (max-width: 1024px){
						margin-bottom: 34.9rem;
					}

					@media (max-width: $md){
						margin-bottom: 7.4rem;
					}
				}

				h3{
					font-size: clamp(20px, 2.4rem, 2.4rem);

					@media (max-width: $xl){
						@include line-height(20,24);
					}

					@media (max-width: 1366px) and (min-width: 1024px) and (min-height: 767px) and (min-aspect-ratio: 4/3) and (hover: none){
						font-size: 11.4rem;
						line-height: 1.36;
					}

					@media (max-width: 1024px){
						font-size: 11.4rem;
						line-height: 1.36;
					}

					@media (max-width: $md){
						@include font-size(24);
					}
				}
			}
		}

		&.right{
			padding-left: 8.6rem;
			padding-right: 8.6rem;
			
			@media (min-width: $md + 1){
				min-width: 244.5rem;
				min-width: 2445px;
				min-width: 4075px;
				max-width: none;
				border-right: .1rem solid rgba($color: #191E2A, $alpha: 0.5);
			}

			@media (max-width: 1366px) and (min-width: 1024px) and (min-height: 767px) and (min-aspect-ratio: 4/3) and (hover: none){
				padding-left: 5.9rem;
				padding-right: 5.1rem;
				min-width: 100%;
				border-bottom: .4px solid $grey-dark;
				padding-bottom: 123px;
			}

			@media (max-width: 1024px){
				padding-left: 5.9rem;
				padding-right: 5.1rem;
				min-width: 100%;
				border-bottom: .4px solid $grey-dark;
				padding-bottom: 123px;
			}

			@media (max-width: $md){
				padding-left: 1.5rem;
				padding-right: 1.5rem;
				padding-bottom: 1rem;
			}

			@media (min-width: 1024px){
				.hd-item {
					flex: calc(100%/5);
					max-width: calc(100%/5);
				}
			}

			.row{
				padding-top: 3.4rem;

				@media (max-width: 1024px){
					padding-top: 99px;
				}

				@media (max-width: $md){
					padding-top: 3.7rem;
				}
			}
		}
	}
}

.home-about-section{

	@media (min-width: $md + 1){
		display: inline-flex!important;
		max-width: none;
	}

	.container{

		&.left{
			
			@media (min-width: $md + 1){
				min-width: 79.9rem;
				max-width: none;
			}

			.row{
				padding-top: 43rem;
				align-items: flex-start;

				@media (max-width: 1366px) and (min-width: 1024px) and (min-height: 767px) and (min-aspect-ratio: 4/3) and (hover: none){
					padding-top: 168px;
				}

				@media (max-width: 1024px){
					padding-top: 168px;
				}

				@media (max-width: $md){
					padding-top: 5.6rem;
				}
			}

			.col-12{

				@media (min-width: $md + 1){
					padding-left: 8.5rem;
				}

				@media (max-width: 1366px) and (min-width: 1024px) and (min-height: 767px) and (min-aspect-ratio: 4/3) and (hover: none){
					padding-left: 7.5rem;
					padding-bottom: 148px;
				}

				@media (max-width: 1024px){
					padding-left: 7.5rem;
					padding-bottom: 148px;
				}

				@media (max-width: $md){
					padding-left: 1.5rem;
					padding-bottom: 4.9rem;
				}
				
				img{
					width: 26.5rem;
					margin-bottom: 24rem;
				}

				.text{
					width: 31rem;

					@media (max-width: 1366px) and (min-width: 1024px) and (min-height: 767px) and (min-aspect-ratio: 4/3) and (hover: none){
						width: 100%;
					}

					@media (max-width: 1024px){
						width: 100%;
					}
				}

				h6{
					margin-bottom: 8.05rem;

					@media (max-width: 1366px) and (min-width: 1024px) and (min-height: 767px) and (min-aspect-ratio: 4/3) and (hover: none){
						margin-bottom: 34.9rem;
					}

					@media (max-width: 1024px){
						margin-bottom: 34.9rem;
					}

					@media (max-width: $md){
						margin-bottom: 7.2rem;
					}
				}

				h3{

					@media (max-width: 1366px) and (min-width: 1024px) and (min-height: 767px) and (min-aspect-ratio: 4/3) and (hover: none){
						font-size: 11.4rem;
						line-height: 1.36;
					}

					@media (max-width: 1024px){
						font-size: 11.4rem;
						line-height: 1.36;
					}

					@media (max-width: $md){
						@include font-size(24);
					}
				}
			}

			.link-con{
				border-top: .1rem solid $stone;
				margin-top: 22.6rem;
				padding-top: .5rem;
				width: 45.246rem;

				@media (max-width: 1366px) and (min-width: 1024px) and (min-height: 767px) and (min-aspect-ratio: 4/3) and (hover: none){
					margin-top: 30.6rem;
					width: 100%;
					padding-top: 42px;
				}

				@media (max-width: 1024px){
					margin-top: 30.6rem;
					width: 100%;
					padding-top: 42px;
				}

				@media (max-width: $md){
					margin-top: 21.3rem;
					padding-top: .6rem;
				}
			}
		}

		&.right{
			aspect-ratio: 1574/1080;
			
			@media (min-width: $md + 1){ 
				max-width: none;
				//width: 1844px;
				object-fit: cover;
				object-position: 50% 50%;
			}

			@media (max-width: 1366px) and (min-width: 1024px) and (min-height: 767px) and (min-aspect-ratio: 4/3) and (hover: none){
				aspect-ratio: 1025.61/1753.08;
				object-fit: cover;
				object-position: 50% 50%;
				padding-bottom: 170.9%;
			}

			@media (max-width: 1024px){
				aspect-ratio: 1025.61/1753.08;
				object-fit: cover;
				object-position: 50% 50%;
			}
		}
	}
}

.home-land-bespoke-builds-section{
	background-color: $blue!important;

	@media (max-width: $md){
		
		& + .contact-section{
			display: none!important;
		}
	}

	*{
		color: $white;
	}

	.row{
		align-items: flex-end;
		align-items: flex-end;
		flex-direction: column;
		width: 157.2rem;
		padding-top: 1rem;
		padding-top: 5.9rem;

		@media (max-width: $xl){
			padding-top: 3.6rem;
		}

		@media (max-width: 1366px) and (min-width: 1024px) and (min-height: 767px) and (min-aspect-ratio: 4/3) and (hover: none){
			padding-top: 134px;

			h1{
				font-size: 81px;
				margin-bottom: 65rem;

				br{
					display: none;
				}
			}
		}

		@media (max-width: 1024px){
			padding-top: 134px;

			h1{
				font-size: 81px;
				margin-bottom: 65rem;

				br{
					display: none;
				}
			}
		}

		@media (max-width: $md){
			padding-top: 5.2rem;

			h1{
				@include font-size(32);
				margin-bottom: 12.5rem;
			}
		}
	}

	.col-12{
		
		@media (min-width: $md + 1){
			padding-left: 8.5rem;
			padding-right: 0;
		}

		@media (max-width: 1366px) and (min-width: 1024px) and (min-height: 767px) and (min-aspect-ratio: 4/3) and (hover: none){
			padding-left: 0;
		}

		@media (max-width: 1024px){
			padding-left: 0;
		}

		@media (max-width: $md){
			padding-left: 1.5rem;
		}

		&:nth-of-type(1){
			margin-bottom: auto;
		}

		&:nth-of-type(2){
			display: flex;
			flex-wrap: wrap;

			.text{
				width: 23%;
				margin-bottom: 5.2rem;

				@media (max-width: $xl){
					width: 33.8%;
					margin-bottom: 3.2rem;
				}

				@media (max-width: 1366px) and (min-width: 1024px) and (min-height: 767px) and (min-aspect-ratio: 4/3) and (hover: none){
					width: 100%;
					margin-bottom: 10.8rem;
				}

				@media (max-width: 1024px){
					width: 100%;
					margin-bottom: 10.8rem;
				}

				@media (max-width: $md){
					margin-bottom: 1rem;
				}

				+ .text{
					margin-left: 11.9rem;

					@media (max-width: 1366px) and (min-width: 1024px) and (min-height: 767px) and (min-aspect-ratio: 4/3) and (hover: none){
						margin-left: 0;
					}

					@media (max-width: 1024px){
						margin-left: 0;
					}
				}

				h6{
					margin-bottom: 1.35em;

					@media (max-width: 1366px) and (min-width: 1024px) and (min-height: 767px) and (min-aspect-ratio: 4/3) and (hover: none){
						margin-bottom: 2.8em;
					}

					@media (max-width: 1024px){
						margin-bottom: 2.8em;
					}

					@media (max-width: $md){
						margin-bottom: .9rem;
					}
				}

				p{
					font-family: $alt-font;
					@include line-height(14,17);

					@media (min-width: $xl + 1){
						font-size: clamp(19px , 1.9rem, 1.9rem);
					}
					
					@media (max-width: $xl){
						font-size: clamp(16px , 1.6rem, 1.6rem);
					}

					@media (max-width: 1366px) and (min-width: 1024px) and (min-height: 767px) and (min-aspect-ratio: 4/3) and (hover: none){
						width: 530px;
						font-size: 29px;
						font-weight: 400;
						line-height: 1.5;
					}

					@media (max-width: 1024px){
						width: 530px;
						font-size: 29px;
						font-weight: 400;
						line-height: 1.5;
					}

					@media (max-width: $md){
						@include font-size(12);
						@include line-height(12,18);
						width: 23rem;
					}
				}
			}
		}

		&:nth-of-type(3){
			margin-bottom: 6.5rem;

			@media (max-width: 1024px){
				margin-bottom: 34.3rem;
			}

			@media (max-width: $md){
				margin-bottom: 9.5rem;
			}
			
			.link-con{
				border-top: .1rem solid white;
				padding-top: .6rem;

				@media (max-width: 1366px) and (min-width: 1024px) and (min-height: 767px) and (min-aspect-ratio: 4/3) and (hover: none){
					margin-top: 15.8rem;
					padding-top: 41px;
				}

				@media (max-width: 1024px){
					margin-top: 15.8rem;
					padding-top: 41px;
				}

				@media (max-width: $md){
					margin-top: 3.9rem;
					padding-top: .5rem;
				}

				*{
					color: $white;
				}
			}
		}
	}

	.col-md-6{
		align-self: flex-end;

		~ .col-12{
			align-self: flex-end;
		}
	}
}

.side-slide-con {

	.contact-section {

		.home &{

			@media (min-width: 1024px + 1){
				
				.row{
					max-height: 100vh;
					overflow: auto;

					.gform_wrapper{
						margin-bottom: 2rem;
					}
				}
			}

			@media (max-width: 1366px) and (min-width: 1024px) and (min-height: 767px) and (min-aspect-ratio: 4/3) and (hover: none){
				display: none !important;
			}
		
			@media (max-width: 1024px){
				display: none !important;
			}
		}
	}
}

.menu-open {

	&:before {
		content:"";
		position: fixed;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		background-color: rgba($color: #000000, $alpha: 0.5);
		z-index: 9;
	}
}

.for-menu {

	@media (min-width: 1024px + 1){
				
		.row{
			max-height: 100vh;
			overflow: auto;

			.gform_wrapper{
				margin-bottom: 2rem;
			}
		}
	}

	@media (max-width: 820px) {

		.form-side {
			max-width: 100% !important;
			flex: 0 0 100% !important;
			padding-right: 1.35rem !important;
			display: flex;
			flex-direction: column;
			justify-content: flex-end;
			border-right: none !important;
		}

		.image-side {
			display: none !important;
		}

		ul.main-menu.main-menu li > a {
			font-size: 46px;
		}

		.text {
			display: none;
		}

		.form-con {
			display: none;
		}

		.gform_wrapper {
			display: none;
		}
	}
}

.enquire-form-section,
.contact-section{
	width: 145.4rem;
	position: relative;
	fieldset#field_1_11 {
		margin-top: 3rem;
	}
	.ginput_container.ginput_container_checkbox {
		margin-top: 2rem;
		margin-bottom: 2rem;
	}

	@media (max-width: $xl){
		width: 169.2rem;
	}

	@media (max-width: 1024px){
		width: 100%;
	}

	&.for-menu{
		width: 145.4rem;
		position: fixed;
		top: 0;
		z-index: 49;
		right: 0;
		background-color: $white;
		transform: translateX(100%);
		transition: 0.6s;

		@media (max-width: $xl){
			width: 169.2rem;
		}

		@media (max-width: 1024px){
			width: 100%;
			overflow: auto;
			max-height: 100vh;
		}

		.menu-open &{
			transform: translateX(0%);
		}
		
		.row{
			min-height: 100vh;
		}

		.image-side{
			border-right: none;
		}
	}

	.container{
		max-width: calc(100% - 14.8rem);

		@media (max-width: 1024px){
			max-width: calc(100% - 5.8rem);
		}

		@media (max-width: $md){
			max-width: 100%;
		}
	}

	.text{
		margin-top: 8.9rem;
		margin-bottom: 6rem;
		width: 30rem;

		@media (max-width: $xl){
			width: 42rem;
		}

		@media (min-width: $md + 1){
			margin-top: 5.65vh;
			margin-bottom: 4.6vh;
			margin-top: 2vh;
			margin-bottom: 0;
		}

		@media (max-width: 1024px){
			width: 290px;
			margin-top: 6.15vh;
			margin-bottom: 5.1vh;
		}

		@media (max-width: $md){
			width: 30rem;
			margin-top: 6rem;
			margin-bottom: 6rem;
		}

		@media (min-width: $xl + 1){
			margin-top: 8.25vh;
			margin-bottom: 5.5vh;
			margin-top: 4.25vh;
			margin-bottom: 1.5vh;
		}

		p{
			font-family: $alt-font;
			font-size: clamp(16px , 1.6rem, 1.6rem);
			@include line-height(16,21);

			@media (max-width: $md){
				@include font-size(16);
			}
		}
	}

	.row{
		
	}

	.col-12{
		padding-top: 8.5rem;

		@media (max-width: $xl){
			padding-top: 8.1rem;
		}

		@media (max-width: 1366px) and (min-width: 1024px) and (min-height: 767px) and (min-aspect-ratio: 4/3) and (hover: none){
			padding-top: 58px;
		}

		@media (max-width: 1024px){
			padding-top: 58px;
		}
	}

	.form-side{

		@media (min-width: $md + 1){
			flex: 0 0 62.8%;
			max-width: 62.8%;
			border-right: .1rem solid $stone;
			padding-right: 0;
		}

		@media (max-width: 1024px){
			flex: 0 0 56%;
			max-width: 56%;
			padding-bottom: 35px;
		}

		@media (max-width: $md){
			flex: 0 0 100%;
			max-width: 100%;
			padding-bottom: 7.6rem;
		}

		@media (min-width: xl + 1){
			flex: 0 0 57%;
			max-width: 57%;
		}

		hr{
			background-color: $stone;
		}
	}

	.image-side{
		display: flex;
		align-items: flex-end;
		padding-bottom: 10.2rem;

		@media (min-width: $md + 1){
			flex: 0 0 36.1%;
			max-width: 36.1%;
			border-right: .1rem solid rgba($color: #191E2A, $alpha: 0.5);
		}

		@media (min-width: xl + 1){
			flex: 0 0 42.4%;
			max-width: 42.4%;
		}

		@media (max-width: 1024px){
			flex: 0 0 40%;
			max-width: 40%;
			padding-bottom: 59px;
		}

		.img-con{
			width: 40.1rem;
			margin: 0 auto;

			@media (max-width: 1024px){
				width: 53.8rem;
			}
		}

		.contact-logo{
			width: 21.3rem;
			margin-bottom: 1.9rem;

			@media (max-width: 1024px){
				width: 28.8rem;
			}
		}

		.main-image{
			width: 100%;
		}
	}

	.top{

		p{
			@include font-size(12);
			font-size: clamp(12px , 1.2rem, 1.2rem);
			margin-bottom: 1.3em;

			@media (max-width: $md){
				@include font-size(12);	
			}
		}

		h6{
			@include font-size(10);
			font-size: clamp(10px , 1.0rem, 1.0rem);
			margin-bottom: .8em;

			@media (max-width: $xl){
				margin-bottom: .1em;
			}

			@media (max-width: $md){
				@include font-size(10);
				margin-bottom: .7em;
			}
		}

		a{
			@include font-size(12);
			font-size: clamp(12px , 1.2rem, 1.2rem);
			@include line-height(12,19);
			text-decoration: none;
			position: relative;

			@media (max-width: $md){
				@include font-size(12);	
			}

			+ hr{
				margin-top: 1.1rem;
			}

			&:hover,
			&:focus-visible{
				
				&:after{
					max-width: 100%;
				}
			}

			&:after{
				content: '';
				position: absolute;
				bottom: 0;
				left: 0;
				width: 100%;
				max-width: 0;
				height: .04rem;
				background-color: currentColor;
				transition: $time;
			}
		}
	}

	.gform_wrapper{
		margin-top: -.5rem;
		padding-right: 9rem;

		@media (max-width: $xl){
			padding-right: 8rem;
			margin-top: -2.3rem;
		}

		@media (max-width: 1024px){
			padding-right: 6rem;
			margin-top: -17px;
		}

		@media (max-width: $md){
			margin-top: 1rem;
		}
	}

	.side-logo{
		position: absolute;
		top: 2.15rem;
		right: 2rem;
		width: 4.2rem;

		@media (max-width: $xl){
			right: 2.9rem;
		}

		@media (max-width: 1366px) and (min-width: 1024px) and (min-height: 767px) and (min-aspect-ratio: 4/3) and (hover: none){
			top: 58px;
		}

		@media (max-width: 1024px){
			top: 58px;
		}

		@media (max-width: $md){
			right: auto;
			left: 1.5rem;
			top: 7rem;
			width: 9.3rem;
			display: none;
		}
	}
}

.contact-section{

	legend, label,
	.gform_wrapper .gform_fields .gfield, .gform_wrapper .gform_fields .gfield--width-half, .gform_wrapper .gform_fields .gfield--width-third{
	
		@media (max-width: $xl){
			margin-bottom: 4px;
		}

		@media (max-width: $md){
			margin-bottom: 2.5rem;
		}
	}

	.gform_wrapper.gform_wrapper.gform_wrapper .gform_fields .gfield, 
	.gform_wrapper.gform_wrapper.gform_wrapper .gform_fields .gfield--width-half, 
	.gform_wrapper.gform_wrapper.gform_wrapper .gform_fields .gfield--width-third{

		@media (min-width: $xl + 1){
			margin-bottom: 15px;
		}
	}

	@media (max-width: $md){
		
		.image-side{
			display: none;
		}

		// .main-menu-con{
		// 	display: none;
		// }
	}

	.text{

		@media (max-width: $xl){

			@media (min-width: 1024px + 1){

				p{
					margin-bottom: .9em;
				}
			}
		}
	}
}

.enquire-form-section{
	width: auto;

	.container{

		@media (max-width: $xl){
			max-width: calc(100% - 21rem);
		}

		@media (max-width: 1024px){
			max-width: calc(100% - 29.2rem);
		}

		@media (max-width: $md){
			max-width: 100%;
		}
	}
	

	.col-12{
		padding-top: 21rem;

		@media (max-width: $xl){
			padding-top: 17.2rem;
		}

		@media (max-width: 1024px){
			padding-top: 120px;
		}

		@media (max-width: $md){
			padding-top: 8.4rem;
		}

		.logo{
			width: 11.431rem;
			width: 114.31px;
			margin-bottom: 12.2rem;

			@media (max-width: $xl){
				margin-bottom: 16.9rem;
			}

			@media (max-width: 1024px){
				margin-bottom: 123px;
			}

			@media (max-width: $md){
				margin-bottom: 11rem;
			}
		}

		@media (min-width: $xl + 1){
			
			.text{
				margin-bottom: 5.3rem;
			}
		}

		@media (max-width: $xl){
			
			.text{
				margin-bottom: 10.4rem;
			}
		}

		.text{

			@media (max-width: $xl){
				width: 52rem;
			}

			@media (max-width: 1024px){
				width: 370px;
				margin-bottom: 82px;
			}

			@media (max-width: $md){
				width: 30rem;
				margin-top: 0;
				margin-bottom: 6.6rem;
			}
		}
	}

	.form-side{
		position: relative;

		&:before{
			content: '';
			position: absolute;
			top: 0;
			right: 0;
			top: 0;
			bottom: 0;
			background-color: $grey;
			width: 100vw;
			max-width: 100vw;
			z-index: -1;
		}

		@media (min-width: $md + 1){
			flex: 0 0 58.2%;
			max-width: 58.2%;
		}

		@media (min-width: $xl + 1){
			flex: 0 0 61.2%;
			max-width: 61.2%;
		}

		@media (max-width: 1135px){
			flex: 0 0 100%;
			max-width: 100%;
			border-right: none;
		}

		.gform_wrapper{
			margin-bottom: 12.4rem;
			width: 73rem;
			
			@media (min-width: 1440px + 1){
				//width: 730px;
				//padding-right: 90px;
			}

			@media (max-width: $xl){
				margin-bottom: 17.4rem;
				width: 100%;
			}

			@media (max-width: 1024px){
				margin-bottom: 123px;
			}

			@media (max-width: $md){
				margin-bottom: 5.8rem;
			}
		}
	}

	.image-side{
		padding-bottom: 12.4rem;

		@media (max-width: $xl){
			padding-bottom: 19.5rem;
		}

		@media (min-width: $md + 1){
			flex: 0 0 41.8%;
			max-width: 41.8%;
			border-right: none;
			padding-left: 10.5rem;
		}

		@media (min-width: $xl + 1){
			flex: 0 0 38.8%;
			max-width: 38.8%;
		}

		@media (max-width: 1135px){
			display: none;
		}

		.img-con{
			width: 47.1rem;

			@media (max-width: $xl){
				width: 53.3rem;
			}
		}
	}
}

.hero-2-section{

	.row{
		padding-top: 25rem;
		align-items: flex-end;

		@media (max-width: $md){
			padding-top: 8rem;
			text-align: center;

			h1{
				margin-bottom: .7em;
			}
		}
	}

	.container{
		max-width: calc(100% - 50.3rem);
		margin-right: 1.5rem;

		@media (max-width: $md){
			max-width: 100%;
		}
	}

	.left{

		@media (min-width: $md + 1){
			flex: 0 0 63.15%;
			max-width: 63.15%;
		}

		@media (min-width: 1024px + 1){
			flex: 0 0 71.55%;
			max-width: 71.55%;
		}
	}

	.right{

		@media (min-width: $md + 1){
			flex: 0 0 36.85%;
			max-width: 36.85%;
			padding-right: 7rem;
		}
		
		@media (min-width: 1024px + 1){
			flex: 0 0 28.45%;
			max-width: 28.45%;
			padding-right: 7.9rem;
		}
	}

	p{
		font-weight: 400;
		
		@media (min-width: $md + 1){
			@include font-size(16);
			@include line-height(16,20);
			margin-bottom: 2.8em;
		}

		@media (max-width: 1024px){
			font-size: 12px;
			margin-bottom: 1.1em;
		}

		@media (max-width: $md){
			width: 26rem;
			margin-left: auto;
			margin-right: auto;

			@media (min-width: $sm + 1){
				@include font-size(11.5);
			}
		}
	}

	.image{
		margin-top: 4.4rem;

		@media (max-width: $md){
			margin-top: .7rem;
			padding-left: 0;
			padding-right: 0;
		}

		img{
			width: 100%;

			@media (max-width: $md){
				aspect-ratio: 390/430;
				object-fit: cover;
				object-position: 50% 50%;
			}
		}
	}
}

.points-section{
	border-bottom: .04rem solid $stone;

	.row{
		padding-top: 9.7rem;
		align-items: flex-end;

		@media (max-width: $md){
			padding-top: 2.5rem;
		}

		@media (max-width: $sm){
			padding-top: 4rem;
		}
	}

	.container{
		max-width: calc(100% - 92.2rem);
		margin-right: 1.5rem;
	
		@media (min-width: 1366px + 1){
			margin-right: 10.5rem;
		}

		@media (max-width: 1366px){
			
			@media (min-width: 1024px + 1){
				margin-right: 10.5rem;
			}
		}

		@media (max-width: $xl){
			max-width: calc(100% - 79.9rem);
		}

		@media (max-width: 1024px){
			max-width: calc(100% - 68.3rem);
		}

		@media (max-width: $md){
			max-width: 100%;
		}
	}

	dl{
		display: flex;
		flex-wrap: wrap;
		margin-bottom: 0;

		dt,
		dd{
			padding-top: 3.3rem;
			margin: 0;

			@media (max-width: 1024px){
				padding-top: 15px;
			}


			@media (max-width: $md){
				padding-top: .2rem;
				padding-left: 9.6rem;
			}

			@media (max-width: $sm){
				padding-top: .6rem;
				padding-left: 7.7rem;
			}

			p,
			li,
			h3{
				font-weight: 400;
			}
		}

		dt{
			position: relative;
			width: 61.3%;

			@media (max-width: $xl){
				width: 59.1%;
			}

			@media (max-width: 1024px){
				width: 50.7%;
			}

			@media (max-width: $md){
				width: 100%;
			}

			@media (max-width: $md){

				&::before{
					content: '';
					position: absolute;
					top: 0;
					right: -2rem;
					width: 300vw;
					max-width: 300vw;
					height: .04rem;
					background-color: $stone;
				}
			}
		}

		dd{
			padding-top: 3.5rem;
			padding-bottom: 3.5rem;
			min-height: 21.6rem;
			width: 38.7%;

			@media (max-width: $xl){
				width: 40.9%;
			}

			@media (max-width: 1024px){
				padding-top: 15px;
				width: 49.3%;
			}

			@media (max-width: $md){
				width: 100%;
				padding-top: 2.7rem;
				min-height: 15.5rem;
			}

			@media (max-width: $sm){
				padding-top: 2.2rem;
				min-height: 15rem;
			}

			&:last-of-type{
				min-height: 20.8rem;

				@media (max-width: $md){
					min-height: 15rem;
				}
			}

			@media (max-width: $md){
				

				@media (min-width: $sm + 1){
					
					p{
						@include font-size(8);
					}
				}
			}

			@media (min-width: $md + 1){
				position: relative;
				padding-right: .5rem;
				
				&::before{
					content: '';
					position: absolute;
					top: 0;
					right: -3rem;
					width: 300vw;
					max-width: 300vw;
					height: .04rem;
					background-color: $stone;

					@media (min-width: 1366px + 1){
						right: -12rem;
					}

					@media (max-width: 1366px){
			
						@media (min-width: 1024px + 1){
							right: -12rem;
						}
					}
				}
			}

		}
	}
}

.title-with-texts-section + .two-images-section{
	border-bottom: none;

	.container{
		margin-right: 2.8rem;
	}

	.row{
		padding-bottom: 17.7rem;

		@media (max-width: 1024px){
			padding-top: 10.4rem;
			padding-bottom: 17.7rem;
		}

		@media (max-width: $md){
			padding-top: .3rem;
			padding-bottom: 6.9rem;
		}
	}
}

.two-images-section{
	border-bottom: .04rem solid $stone;

	@media (max-width: $xl){
		
		&:has(+ .meet-the-team-section){
			border-bottom: none;
		}
	}

	.row{
		padding-top: 3.3rem;
		padding-bottom: 21.6rem;
		align-items: flex-end;

		@media (max-width: $md){
			padding-top: 4.3rem;
			padding-bottom: 1rem;
		}
	}

	.container{
		max-width: calc(100% - 28.9rem);
		margin-right: 1.5rem;

		@media (max-width: $md){
			max-width: 100%;
		}
	}

	img{
		width: 100%;

		@media (max-width: $md){
			aspect-ratio: 394/373;
			object-fit: cover;
			object-position: 50% 50%;
		}
	}

	.col-12{

		@media (max-width: $md){
			padding: 0;
		}
	}

	.left{

		@media (min-width: $md + 1){
			padding-right: 1.1rem;
		}

		@media (max-width: $md){
			// order: 2;
			// margin-top: 1.4rem;
			margin-bottom: 1.4rem;
		}
	}

	.right{

		@media (min-width: $md + 1){
			padding-left: 1.1rem;
		}

		
	}
}

.meet-the-team-section{

	.row{
		padding-top: 8.4rem;
		padding-top: 84px;
		padding-bottom: 13rem;
		padding-bottom: 130px;

		@media (max-width: 1024px){
			padding-top: 71px;
			padding-bottom: 49px;
		}

		@media (max-width: $md){
			padding-top: 1.6rem;
			padding-bottom: 5.7rem;
		}
	}

	.container{
		max-width: calc(100% - 12.7rem);
		margin-right: 1.5rem;

		@media (max-width: $md){
			max-width: 100%;
		}
	}

	@media (max-width: $md){
		
		.container,
		.col-12{
			padding-right: 1.2rem;
			padding-left: 1.2rem;
		}

		.row{
			margin-right: -1.2rem;
			margin-left: -1.2rem;
		}
	}

	.title-con{
		margin-bottom: 10.1rem;

		@media (min-width: $md + 1){
			padding-left: 2rem;
		}

		@media (max-width: 1024px){
			margin-bottom: 8.3rem;
		}

		@media (max-width: $md){
			margin-bottom: 6.3rem;
		}
	}

	.col-md-4{
		margin-bottom: 8.5rem;

		@media (max-width: $xl){
			margin-bottom: 6.3rem;
		}

		@media (max-width: 1024px){
			margin-bottom: 11px;
		}

		@media (max-width: $md){
			margin-bottom: 3.1rem;
		}

		.inner{

			.top{
				display: flex;
				align-items: center;
				flex-wrap: wrap;

				.name{
					@include font-size(17);
					font-size: clamp(11px , 1.7rem, 1.7rem);
					font-family: $alt-font;
					font-weight: 400;
					min-width: 17.4rem;
					padding-right: 1rem;

					@media (max-width: 1024px){
						font-size: 12px;
						min-width: 134px;
					}

					@media (max-width: $md){
						@include font-size(16);
						min-width: 17.7rem;
					}
				}

				.role{
					font-family: $alt-font;

					@media (max-width: 1024px){
						font-size: 10px;
					}

					@media (max-width: $md){
						@include font-size(14);
					}
				}
			}

			img{
				margin-top: .5rem;
				margin-bottom: .9em;
				width: 100%;

				@media (max-width: $md){
					margin-top: 0;
					aspect-ratio: 366/289;
					object-fit: cover;
					object-position: 50% 50%;
					margin-bottom: 0.8rem;
				}

			}

			.bio{
				width: 37rem;

				@media (max-width: $xl){
					width: 44.3rem;
				}

				@media (max-width: 1024px){
					width: 100%;
				}

				@media (max-width: $md){
					width: 83.9%;
				}

				p,
				li{
					font-weight: 400;

					@media (min-width:576px) and (max-width: 820px) {
						font-size: 0.8rem;
					}
				}
			}
		}
	}
}

.two-images-section + .get-in-touch-section{
	background-color: $blue;
	margin-top: 2.4rem;

	@media (max-width: $xl){
		margin-top: 4.7rem;
	}

	@media (max-width: $md){
		margin-top: -6.9rem;
	}
}

.get-in-touch-section{
	background-color: $orange;

	.home & {
		display: none;
	}

	*{
		color: white;
	}

	.container {
		max-width: 170.2rem;
		position: relative;

		&:after{
			content: '';
			position: absolute;
			bottom: 0;
			left: 50%;
			width: 100vw;
			max-width: 100vw;
			transform: translateX(-50%);
			height: 27.4rem;
			background-image: url(../images/get-back.svg);
			background-size: 7.6rem auto;

			@media (max-width: $md){
				height: 13.1rem;
				background-size: 4.7rem auto;
			}
		}	
	}

	img{
		
		@media (max-width: $md){
			aspect-ratio: 390/510;
			object-fit: cover;
			object-position: 50% 50%;
		}
	}

	.row{
		padding-top: 26.6rem;
		padding-bottom: 40.9rem;

		@media (max-width: $md){
			padding-top: 10.5rem;
			padding-bottom: 19.5rem;
			text-align: center;
		}
	}

	h1{
		width: 100rem;
		margin-bottom: 0;

		@media (max-width: $md){
			width: 30rem;
			margin-left: auto;
			margin-right: auto;
			margin-bottom: .3em;
		}
	}

	.link{
		margin-left: .9rem;
	}
}

.get-all-section{
	background-color: $colour;
	text-align: center;
	border-bottom: .04rem solid white;

	.home &{
		display: none;

		@media (max-width: 1024px) {
			display: block;
		}
	}

	.row{
		padding-top: 18.8rem;
		padding-bottom: 16.5rem;

		@media (max-width: $xl){
			padding-top: 20.4rem;
			padding-bottom: 21.5rem;
		}

		@media (max-width: 1024px){
			padding-top: 23.8rem;
			padding-bottom: 25.7rem;
		}

		@media (max-width: $md){
			padding-top: 9.1rem;
			padding-bottom: 6rem;
		}
	}

	img{
		margin: 0 auto 4rem;
		width: 5.8rem;

		@media (max-width: 1024px){
			margin: 0 auto 5.5rem;
			width: 7.8rem;
		}

		@media (max-width: $md){
			margin: 0 auto 2.3rem;
			width: 4.1rem;
		}
	}

	*{
		color: white;
	}

	h4{
		@include font-size(20);
		@include line-height(20,24);
		margin-right: auto;
		margin-left: auto;
		margin-bottom: .4em;
		width: 28rem;

		@media (max-width: $xl){
			font-size: clamp(13px , 1.3rem, 1.3rem);
			margin-bottom: 1.4em;
		}

		@media (max-width: 1024px){
			width: 38rem;
			margin-bottom: 1.4em;
			margin-bottom: 1em;
		}

		@media (max-width: $md){
			@include font-size(16);
			@include line-height(16,20);
			width: 24rem;
			margin-left: auto;
			margin-right: auto;
		}
	}

	h1{
		font-weight: 400;
		border-bottom: .04rem solid currentColor;
		display: inline-block;

		@media (min-width: 1024px + 1){
			@include font-size(75);
		}

		@media (max-width: 1024px){
			font-size: 53px;
			font-size: 11.82rem;
		}

		@media (max-width: $md){
			@include font-size(32);
		}
	}
}

.title-with-texts-section{
	margin-top: 17.3rem;
	border-top: .04rem solid #191E2A;

	@media (max-width: 1024px){
		margin-top: 21.9rem;
	}

	@media (max-width: $md){
		margin-top: 10.4rem;
	}

	&.no-bottom{

		.row{
			padding-bottom: 15rem;
		}

		.col-md-8{

			@media (min-width: $md + 1){
				flex: 0 0 51.1%;
				max-width: 51.1%;
			}
		}

		.col-md-4{

			@media (min-width: $md + 1){
				flex: 0 0 48.9%;
				max-width: 48.9%;
				padding-right: 14rem;
			}
		}
	}

	~ .title-with-texts-section{
		margin-top: 0;
	}

	.row{
		padding-top: 2.7rem;
		padding-bottom: 7.2rem;

		@media (max-width: $md){
			padding-top: 1.9rem;
			padding-bottom: 4rem;
		}
	}

	.container{
		max-width: calc(100% - 8.8rem);
		margin-right: 1.5rem;
		
		@media (max-width: $md){
			max-width: 100%;
			margin-right: 0;
		}
	}

	.col-md-8{

		@media (min-width: $md + 1){
			flex: 0 0 63.15%;
    		max-width: 63.15%;
			padding-left: 4.7rem;
		}

		@media (min-width: $xl + 1){
			flex: 0 0 71.65%;
    		max-width: 71.65%;
			padding-left: 4.7rem;
		}
	}

	h2{
		font-weight: 400;

		@media (max-width: $md){
			@include font-size(20);
			margin-bottom: .8em;
		}
	}

	.col-md-4{

		@media (min-width: $md + 1){
			flex: 0 0 36.85%;
    		max-width: 36.85%;
			padding-right: .75rem;
		}

		@media (min-width: $xl + 1){
			flex: 0 0 28.35%;
    		max-width: 28.35%;
			padding-right: 8.6rem;
		}

		&.top{
			min-height: 19.8rem;

			@media (max-width: $md){
				min-height: 11rem;
			}
		}
	}

	.filler{
		position: relative;
		
		&::before{
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: 100vw;
			max-width: 100vw;
			height: .04rem;
			background-color: #191E2A;

			@media (max-width: $md){
				left: 1.5rem;
			}
		}
	}

	.bottom{
		padding-top: 5.2rem;

		@media (max-width: $md){
			padding-top: 3rem;

			p{
				width: 31.2rem;
				font-size: 16px;
				line-height: 20px;
			}
		}

		.link-con{
			position: relative;
			margin-top: 11.1rem;
			padding-top: .7rem;

			@media (max-width: $xl){
				margin-top: 9.1rem;
				padding-top: .3rem;
			}

			@media (max-width: 1024px){
				margin-top: 10.1rem;
			}

			@media (max-width: $md){
				margin-top: 9.4rem;
			}

			&::before{
				content: '';
				position: absolute;
				top: 0;
				left: 0;
				width: 100vw;
				max-width: 100vw;
				height: .04rem;
				background-color: $stone;
			}
		}
	}
}

.development-archive-section{
	margin-top: 17.3rem;
	border-top: .04rem solid #191E2A;
	background-color: $grey;

	@media (max-width: 1024px){
		margin-top: 21.9rem;
	}

	@media (max-width: $md){
		margin-top: 10.4rem;
	}

	.container{
		max-width: calc(100% - 18.6rem);
		margin-right: 1.5rem;

		@media (max-width: $xl){
			max-width: calc(100% - 17.8rem);
		}

		@media (max-width: $md){
			max-width: 100%;
		}
	}

	.row{
		padding-bottom: 17.4rem;

		@media (max-width: $xl){
			padding-bottom: 12.4rem;
			
			@media (min-width: $md + 1){
				padding-right: 8.5rem;
			}
		}

		@media (max-width: 1024px){
			padding-bottom: 9.5rem;
		}

		@media (max-width: $md){
			padding-bottom: 4.3rem;
		}
	}

	@media (min-width: $md + 1){
		
		.col-12{
			padding-right: 4.6rem;
			padding-left: 4.6rem;
		}

		.row{
			margin-right: -4.6rem;
			margin-left: -4.6rem;
		}
	}

	.text-con{
		padding-top: 1.9rem;
		padding-bottom: 12.5rem;

		@media (max-width: $xl){
			padding-bottom: 11.5rem;
		}

		@media (max-width: 1024px){
			padding-bottom: 11rem;
		}

		@media (max-width: $md){
			padding-top: 1.9rem;
			padding-bottom: 3.8rem;
		}

		.text{
			width: 65rem;

			h3{

				@media (max-width: $xl){
					font-size: clamp(16px, 2.2rem, 2.2rem)
				}

				@media (max-width: 1024px){
					font-size: 16px;
				}

				@media (max-width: $md){
					@include font-size(20);
					@include line-height(20,24);
					width: 25rem;
				}
			}
		}
	}

	.filter{
		display: flex;
		align-items: center;
		margin-bottom: 5.6rem;

		@media (max-width: 1024px){
			margin-bottom: 5rem;
		}

		@media (max-width: $md){
			margin-bottom: 2.1rem;
		}

		a{
			@include font-size(12);
			font-size: clamp(12px , 1.2rem, 1.2rem);
			@include line-height(12,16);
			letter-spacing: .3em;
			text-decoration: none;
			text-transform: uppercase;

			&.active{
				pointer-events: none;

				span{
					font-weight: 400;
				}
			}

			&:has(+ a):after{
				content: '/';
				margin: 0 .5em;
			}

			&:hover,
			&:focus-visible{
				color: currentColor;
				
				span:after{
					max-width: 100%;
				}
			}

			span{
				position: relative;

				&:after{
					content: '';
					position: absolute;
					bottom: 0;
					left: 0;
					width: 100%;
					max-width: 0;
					height: .04rem;
					background-color: currentColor;
					transition: $time;
				}
			}
		}
	}
}

.hd-item,
.d-item{
	margin-bottom: 5.3rem;

	@media (max-width: $md){
		margin-bottom: 2.4rem;
	}

	.inner{
		position: relative;

		a{
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			z-index: 2;

			&:hover,
			&:focus-visible{
				
				~ .img-con .over{
					opacity: 1;
				}

				~ h4:after{
					max-width: 100%;
				}
			}
		}

		.img-con{
			width: 100%;
			aspect-ratio: 358/225;
			margin-bottom: 1.1rem;
			position: relative;

			@media (max-width: $md){
				aspect-ratio: 367.5/302.23;
				object-fit: cover;
				object-position: 50% 50%;
				margin-bottom: 1.9rem;
			}

			.over{
				background-color: rgba($grey-dark, .33);
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				display: flex;
				justify-content: center;
				align-items: center;
				opacity: 0;
				transition: $time;

				img{
					max-width: calc(100% - 12.6rem);
				}
				
			}
		}

		h4{
			margin-bottom: -.2em;
			display: inline-block;
			position: relative;

			&:after{
				content: '';
				position: absolute;
				bottom: 0;
				left: 0;
				width: 100%;
				max-width: 0;
				height: .04rem;
				background-color: currentColor;
				transition: $time;
			}
		}

		.location{
			@include font-size(11);
			font-size: clamp(12px , 1.2rem, 1.2rem);
			@include line-height(10,17);

			@media (max-width: $xl){
				@include line-height(10,20);
			}

			@media (max-width: 1024px){
				font-size: 10px;
			}
		}
	}
}

.d-item{

	.inner{

		.img-con{

			.over{

				img{
					max-width: calc(100% - 20rem);
				}
			}
		}
	}
}

.hd-item{
	margin-bottom: 0;

	@media (max-width: 1366px) and (min-width: 1024px) and (min-height: 767px) and (min-aspect-ratio: 4/3) and (hover: none){
		flex: 0 0 100%;
		max-width: 100%;
		margin-bottom: 28px;
	}

	@media (max-width: 1024px){
		flex: 0 0 100%;
		max-width: 100%;
		margin-bottom: 28px;
	}

	@media (max-width: $md){
		margin-bottom: 0.4rem;
	}

	.inner{
		display: flex;
		flex-wrap: wrap;

		.img-con{
			aspect-ratio: 0;
			height: calc(100vh - 3.4rem - 16rem);
			margin-bottom: 2.1rem;

			@media (max-width: $xl){
				margin-bottom: 1.3rem;
			}

			@media (max-width: 1366px) and (min-width: 1024px) and (min-height: 767px) and (min-aspect-ratio: 4/3) and (hover: none){
				height: auto;
				aspect-ratio: 963/600;
				padding-bottom: 62.3%;
				object-fit: cover;
				object-position: 50% 50%;
				margin-bottom: 43px;
			}

			@media (max-width: 1024px){
				height: auto;
				aspect-ratio: 963.81/600;
				object-fit: cover;
				object-position: 50% 50%;
				margin-bottom: 43px;
			}

			@media (max-width: $md){
				margin-bottom: 1.6rem;
			}
		}

		h3{
			width: calc(100% - 17.873rem);

			@media (max-width: $xl){
			
				@media (min-width: 1024px + 1){
					width: calc(100% - 128px);
				}
			}

			@media (max-width: $xl){
				font-size: clamp(16px , 2.2rem, 2.2rem);
			}

			@media (max-width: 1366px) and (min-width: 1024px) and (min-height: 767px) and (min-aspect-ratio: 4/3) and (hover: none){
				font-size: 41px;
				padding-top: 10px;
				width: 67.6%;
			}

			@media (max-width: 1024px){
				font-size: 41px;
				padding-top: 10px;
				width: 67.6%;
			}

			@media (max-width: $md){
				@include font-size(16);
			}
			
		}
	}

	.inner + .link-con{
		margin-top: .7rem;
	}

	.link-con{
		width: 17.873rem;
		border-top: .04rem solid $stone;
		margin-top: 1.5rem;
		display: flex;
		justify-content: flex-end;
		padding-right: 2rem;
		padding-top: .7rem;
		float: right;

		@media (max-width: $xl){
			
			@media (min-width: 1024px + 1){
				width: 128px;
			}
		}

		@media (max-width: $xl){
			margin-top: .6rem;
		}

		@media (max-width: 1366px) and (min-width: 1024px) and (min-height: 767px) and (min-aspect-ratio: 4/3) and (hover: none){
			margin-top: 0;
			width: 32.4%;
			justify-content: flex-start;
			align-self: flex-start;
			padding-top: 42px;
		}

		@media (max-width: 1024px){
			margin-top: 0;
			width: 32.4%;
			justify-content: flex-start;
			align-self: flex-start;
			padding-top: 42px;
		}

		@media (max-width: $md){
			padding-top: .6rem;
			padding-right: 0;
			// justify-content: flex-end;
		}
	}
}

.property-hero-section{
	position: relative;

	@media (max-width: $md){
		background-image: none!important;
	}

	&:after{
		content: '';
		background-color: rgba($grey-dark, .4);
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;

		@media (max-width: $md){
			display: none;
		}
	}

	&.green{

		.right-side .text{
			background-color: $green;

			*{
				color: white;
			}
		}
	}

	&.blue{

		.right-side .text{
			background-color: $blue;

			*{
				color: white;
			}
		}
	}

	&.orange{

		.right-side .text{
			background-color: $orange;
		}
	}

	&.bone{

		.right-side .text{
			background-color: $cream;
		}
	}

	&::after{
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba($grey-dark, .4);
	}
	
	.container{
		max-width: 149.1rem;
		position: relative;
		z-index: 2;
	}

	.row{
		padding-top: 15.8rem;
		padding-bottom: 15.8rem;

		@media (max-width: $md){
			padding-top: 5.8rem;
			padding-bottom: .5rem;
		}
	}

	.left-side{
		display: flex;

		@media (min-width: $md + 1){
			padding-right: 0;
		}

		.inner{
			background-color: white;
			text-align: center;
			padding: 9.8rem 1rem 3rem;
			position: relative;
			width: 100%;

			@media (max-width: $md){
				padding: 3.9rem 2.5rem 7.5rem;
			}

			&:after{
				content: '';
				position: absolute;
				top: 50%;
				left: 99.45%;
				transform: translate(-50%, -50%);
				width: 23.474rem;
				aspect-ratio: 234.74/269.81;
				z-index: 2;
				background-image: url(../images/side-logo.svg);
				background-repeat: no-repeat;
				background-size: 100% auto;

				@media (max-width: $md){
					left: 50%;
					top: 101.4%;
					width: 13.381rem;
				}
			}
		}

		img{
			margin: 0 auto 2.1rem;
			width: 43.1rem;

			@media (max-width: 1024px){
				margin: 0 auto 1.5rem;
			}

			@media (max-width: $md){
				aspect-ratio: 604/455;
				object-fit: cover;
				object-position: 50% 50%;
				margin-bottom: .9rem;
			}
		}

		h2{

			@media (max-width: $md){
				@include font-size(20);
				margin-bottom: 0.7em;
			}
		}

		h3{
			font-weight: 400;
			margin-bottom: 2.24em;

			@media (max-width: 1024px){
				margin-bottom: 1.94em;
			}

			@media (max-width: $md){
				margin-bottom: 1.6em;
			}
		}

		h6{
			font-family: $alt-font;
			letter-spacing: 0;

			@media (max-width: 1024px){
				font-size: 9px;
			}

			@media (max-width: $md){
				@include font-size(12);
			}
		}
	}

	.right-side{
		padding-left: 0;
		display: flex;
		flex-direction: column;
		justify-content: space-between;

		@media (max-width: 1024px){
			align-items: center;
		}

		@media (max-width: $md){
			padding-right: 0;
		}

		.text{
			width: 100%;
			flex-grow: 1;
			padding: 13.5rem 7rem 1rem 22.5rem;

			@media (max-width: $xl){
				padding: 11rem 7rem 1rem 19.2rem;
			}

			@media (max-width: 1024px){
				display: flex;
				flex-direction: column;
				justify-content: center;
				padding: 4.2rem 7rem 2rem 19.2rem;
			}

			@media (max-width: $md){
				padding: 11.2rem 4.4rem 9.9rem;
				min-height: 50rem;
				justify-content: flex-start;
			}
		}

		h3{
			font-weight: 400;
			margin-bottom: .84em;

			@media (max-width: $xl){
				margin-bottom: .54em;
			}

			@media (max-width: 1024px){
				font-size: 17px;
			}

			@media (max-width: $md){
				@include font-size(24);
				margin-bottom: .8em;
			}
		}

		p,
		li{
			@include font-size(12);
			font-size: clamp(12px , 1.2rem, 1.2rem);
			@include line-height(12,18);
			font-weight: 400;

			@media (max-width: 1024px){
				line-height: 1.3;
			}

			@media (max-width: $md){
				@include font-size(12);
				@include line-height(12,18);
			}
		}

		img{

			@media (max-width: 1024px){
				display: none;
			}

			@media (max-width: $md){
				display: block;
				margin-left: 2.8rem;
				margin-right: 2.8rem;
				width: calc(100% - 1.6rem - 1.6rem);
				margin-top: -8.6rem;
				aspect-ratio: 366/258;
				object-fit: cover;
				object-position: 50% 50%;
			}

		}
	}
}

.links-and-gallery-section{
	border-bottom: .04rem solid #191E2A;
	background-color: $grey;

	@media (max-width: $md){
		border-bottom: none;

		&:last-child{

			.right-side{
				padding-bottom: 0;
			}
		}
	}

	.container{
		max-width: 100%;
	}

	.left-side{
		padding-top: clamp(59px, 6.63rem, 6.63rem);
		padding-bottom: 6.63rem;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		padding-left: 4.2rem;
		padding-right: 4.2rem;
		
		@media (min-width: $md + 1){
			flex: 0 0 30.7%;
			max-width: 30.7%;
			border-right: .04rem solid $stone;
		}

		@media (min-width: 1024px + 1){
			flex: 0 0 26.1%;
			max-width: 26.1%;
		}

		@media (min-width: $xl + 1){
			flex: 0 0 22.3%;
			max-width: 22.3%;
		}

		@media (max-width: $xl){
			padding-left: 2.6rem;
			padding-right: 2.6rem;
			padding-bottom: 10.73rem;
		}

		@media (max-width: 1024px){
			padding-top: 20px;
			padding-bottom: 24px;
			padding-left: 3.6rem;
		}

		@media (max-width: $md){
			padding-top: 2rem;
			padding-left: 1.6rem;
			padding-right: 1.6rem;
		}

		.top{

			@media (max-width: $md){
				margin-bottom: 13.8rem;
				display: none;

				&:has(img){
					display: block;
				}
			}

			img{
				margin-left: 5.5rem;
				width: 15.9385rem;

				@media (max-width: $xl){
					margin-left: 0;
					width: 15.0385rem;
				}

				@media (max-width: $md){
					width: 15.9385rem;
				}
			}
		}

		.btn{
			margin-bottom: clamp(29px, 2.9rem, 2.9rem);
			min-width: 178px;
			letter-spacing: .3em;

			@media (max-width: 1024px){
				margin-bottom: 32px;
			}

			@media (max-width: 820px){
				min-width: unset;
				font-size: 14px!important;
				padding: 24.04px 62px;
			}


		}

		.links{
			width: 260px;
			border-bottom: .04rem solid $stone;
			margin-bottom: clamp(23px, 2.3rem, 2.3rem);

			@media (max-width: 1024px){
				margin-bottom: 22px;
			}

			@media (max-width: $md){
				width: 21.4rem;
			}

			&:has(+ .links){
				margin-bottom: clamp(19.5px, 1.95rem, 1.95rem);

				@media (max-width: 1024px){
					margin-bottom: 20px;
				}
			}

			&:has(a:hover),
			&:has(a:focus-visible){
				border-bottom: .04rem solid $grey-dark;
			}

			a{
				text-decoration: none;
				text-transform: uppercase;
				@include font-size(12);
				font-size: clamp(12px , 1.2rem, 1.2rem);
				@include line-height(12,17);
				letter-spacing: .3em;
				margin-bottom: .6rem;
				display: inline-block;
				position: relative;

				@media (max-width: 1024px){
					margin-bottom: 7px;
				}

				@media (max-width: $md){
					
					@media (min-width: $sm + 1){
						font-size: 12px;
					}
				}

				// &:hover,
				// &:focus-visible{
				// 	color: currentColor;
					
				// 	&:after{
				// 		max-width: 100%;
				// 	}
				// }

				&:after{
					content: '';
					position: absolute;
					bottom: 0;
					left: 0;
					width: 100%;
					max-width: 0;
					height: .04rem;
					background-color: currentColor;
					transition: $time;
				}
			}
		}

		.link{

			@media (max-width: $md){
					
				@media (min-width: $sm + 1){
					font-size: 12px;
					padding-right: 25px;

					i{
						width: 9px;
						height: 9px;

						&:before,
						&:after{
							width: 9px;
							height: 1px;
						}
					}
				}
			}
		}
	}

	.right-side{
		padding-top: clamp(59px, 6.63rem, 6.63rem);
		overflow: hidden;
		
		@media (min-width: $md + 1){
			flex: 0 0 69.3%;
			max-width: 69.3%;
			padding-left: clamp(39px, 9.4rem, 9.4rem);
			padding-right: clamp(39px, 9.4rem, 9.4rem);
		}

		@media (min-width: 1024px + 1){
			flex: 0 0 73.9%;
			max-width: 73.9%;
			padding-left: clamp(75px, 9.4rem, 9.4rem);
			padding-right: clamp(75px, 9.4rem, 9.4rem);
		}

		@media (min-width: $xl + 1){
			flex: 0 0 77.7%;
			max-width: 77.7%;
		}

		@media (max-width: 1024px){
			padding-top: 35px;
			padding-bottom: 35px;
		}

		@media (max-width: $md){
			padding-top: 1.1rem;
		}

		.swiper-wrapper-con{
			overflow: visible!important;
		}

		img{
			height: 86.268rem;
			height: calc(100dvh - 20.7rem);

			@media (max-width: $xl){
				height: calc(100dvh - 25rem);
			}

			// @media (max-width: $xl){
			// 	height: 81.568rem;
			// 	height: 60.568rem;
			// 	height: calc();
			// }

			@media (max-width: $md){
				margin-bottom: 1.2rem;
				height: auto;
			}
		}

		.gallery-slide{
			margin-bottom: 7.13rem;
			width: calc(100% + 2.115rem + 2.115rem);
			max-width: calc(100% + 2.115rem + 2.115rem);
			margin-left: -2.115rem;

			@media (max-width: $xl){
				margin-bottom: 75px;
			}

			@media (max-width: 1024px){
				margin-bottom: 0;
			}

			@media (max-width: $md){
				max-height: 0!important;
				overflow: hidden!important;
				padding: 0;
			}

			.swiper-slide{
				width: auto;
				padding: 0 2.115rem;
			}
		}

		.mob-images{

			@media (min-width: $md + 1){
				display: none;
			}
		}
	}
}

.title-and-images-section{

	.row{
		padding-top: 15rem;
		padding-bottom: 15rem;
		align-items: flex-end;

		@media (max-width: 1024px){
			padding-top: 19.9rem;
			padding-bottom: 14.9rem;
		}

		@media (max-width: $md){
			padding-top: 7rem;
			padding-bottom: 2.9rem;
		}
	}

	.title-con{
		text-align: center;
		margin-bottom: 14.9rem;

		@media (max-width: $md){
			margin-bottom: 7.4rem;
		}

		h2{
			width: 44rem;
			margin-left: auto;
			margin-right: auto;
			font-weight: 400;

			@media (max-width: 1024px){
				font-size: 18px;
			}

			@media (max-width: $md){
				@include font-size(20);
				margin-left: auto;
				margin-right: auto;
				width: 28rem;
			}
		}
	}

	.left{

		@media (max-width: $md){
			order: 3;
			padding-left: 0;
		}
		
		img{
			width: 72.8rem;

			@media (max-width: $md){
				width: 94.1%;
				aspect-ratio: 351/320;
				object-fit: cover;
				object-position: 50% 50%;
			}
		}
	}

	.right{
		display: flex;
		justify-content: flex-end;

		@media (max-width: $md){
			padding-right: 0;
			margin-bottom: 3rem;
			order: 2;
		}

		img{
			width: 65.9rem;

			@media (max-width: $md){
				width: 87.1%;
			}
		}
	}
}

.wide-image-section{

	.container{
		max-width: 176.2rem;
	}

	.row{
		padding-top: 25rem;
		padding-bottom: 27.7rem;
		align-items: flex-end;

		@media (max-width: $md){
			padding-top: 0;
			padding-bottom: 0;
		}
	}

	.col-12{
		padding-left: 2.45rem;

		@media (max-width: $md){
			padding-left: 0;
			padding-right: 0;
		}
	}

	img{
		width: 100%;

		@media (max-width: $md){
			aspect-ratio: 390/480.38;
			object-fit: cover;
			object-position: 50% 50%;
		}
	}
}

.specifications-section{
	background-color: $green;

	.container{
		max-width: 172.5rem;
	}

	.row{
		padding-top: 20.1rem;
		padding-bottom: 19.5rem;

		@media (max-width: $md){
			padding-top: 7rem;
			padding-bottom: 2.4rem;
		}
	}

	*{
		color: white;
	}

	h1{
		font-weight: 400;
	}

	.title-con{
		padding-bottom: 2.2rem;
		position: relative;

		@media (max-width: $md){
			padding-bottom: 1.8rem;
		}

		&:after{
			content: '';
			position: absolute;
			bottom: 0;
			left: 50%;
			width: 200vw;
			max-width: 200vw;
			transform: translateX(-50%);
			height: .04rem;
			background-color: white;
		}
	}

	.col-md-4{

		@media (min-width: $md + 1){
			flex: 0 0 34.2%;
  			max-width: 34.2%;
		}
	}

	.col-md-8{
		display: flex;
		flex-wrap: wrap;
		justify-content: flex-end;

		@media (min-width: $md + 1){
			flex: 0 0 65.8%;
  			max-width: 65.8%;
		}
	}

	.specifications{
		width: 100%;
		margin-bottom: 4.3rem;

		@media (max-width: $xl){
			margin-bottom: 3.6rem;
		}

		@media (max-width: 1024px){
			margin-bottom: .8rem;
		}

		.specification{
			position: relative;
			min-height: 11.92rem;
			display: flex;
			justify-content: flex-end;

			@media (max-width: 1024px){
				min-height: 14.02rem;
			}

			@media (max-width: $md){
				min-height: 9.92rem;
			}

			&:last-of-type{
				
				&:after{
					display: none;
				}
			}

			.number{
				position: absolute;
				top: 2.7rem;
   				left: -18.2rem;
				@include font-size(12);
				font-size: clamp(10px , 1.2rem, 1.2rem);
				@include line-height(12,16);
				letter-spacing: .3em;

				@media (max-width: 1024px){
					top: 1.2rem;
					font-size: 8px;
				}

				@media (max-width: $md){
					left: 0;
					@include font-size(12);
					letter-spacing: 0;
					top: 1rem;
					
				}
			}

			p{
				padding-top: 3rem;
				width: 58.1rem;
				@include font-size(16);
				font-size: clamp(12px , 1.6rem, 1.6rem);
				@include line-height(16,17);
				font-weight: 400;
				padding-right: 20rem;

				@media (max-width: $xl){
					padding-top: 1.35rem;
					@include line-height(12,16);
					width: 49.9rem;
					padding-right: 6rem;
				}

				@media (max-width: 1024px){
					padding-top: .75rem;
					width: 56.8rem;
					padding-right: 0;
					font-size:12px;
				}

				@media (max-width: $md){
					padding-left: 10rem;
					padding-top: 1rem;
				}
			}

			&:after{
				content: '';
				position: absolute;
				bottom: 0;
				left: 0;
				width: 200vw;
				max-width: 200vw;
				height: .04rem;
				background-color: white;
				@media (min-width: 1024px) and (max-width: 1600px){
					height: 1px;
					opacity: 0.4;
				}
				@media (max-width: $md){
					left: -50vw;
				}
			}
		}
	}

	.btn-bottom{
		width: 58.1rem;

		@media (max-width: $xl){
			width: 49.9rem;
		}

		@media (max-width: 1024px){
			width: 56.8rem;
		}

		@media (max-width: $md){
			padding-top: 2.4rem;
			padding-bottom: 2.5rem;
		}

		.btn{
			min-width: 17.8rem;
			letter-spacing: .3em;

			@media (max-width: 1024px){
				font-size: 8px !important;
				padding: 8px 22px;
				min-width: 112px;
			}

			@media (max-width: $md){
				//font-size: clamp(13px , 1.3rem, 1.3rem)!important;
				//padding: 11.04px 22px;
				//min-width: 17.2rem;
				font-size: 14px!important;
				padding: 24.04px 62px;
			}
		}
	}

	.under{
		display: flex;
		justify-content: flex-end;
		width: 100%;

		p{
			padding-top: 4.3rem;
			width: 58.1rem;
			@include font-size(12);
			font-size: clamp(10px , 1.2rem, 1.2rem);
			@include line-height(12,15);
			font-weight: 400;
			padding-right: 15rem;

			@media (max-width: $xl){
				width: 49.9rem;
				padding-right: 0;
			}

			@media (max-width: 1024px){
				width: 56.8rem;
				padding-top: 19px;
				@include font-size(10);
			}

			// @media (max-width: $md){
			// 	@include font-size(12);
			// }
		}
	}
}
















//
//!!BLOCKS END
//

@media (min-width: $md + 1){
	
	.container{
		max-width: ($base-widthWO + $grid-gutter-widthWO) / 10 + rem;
	}

	.container,
	.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col, .col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm, .col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md, .col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg, .col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl, .col-xl-auto{
		padding-right: $grid-gutter-widthWO / 2 / 10 + rem;
		padding-left: $grid-gutter-widthWO / 2 / 10 + rem;
	}

	.row{
		margin-right: -$grid-gutter-widthWO / 2 / 10 + rem;
		margin-left: -$grid-gutter-widthWO / 2 / 10 + rem;
	}
}

@media (max-width: $md){

	.container,
	.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col, .col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm, .col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md, .col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg, .col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl, .col-xl-auto{
		padding-right: 1.6rem;
		padding-left: 1.6rem;
	}

	.row{
		margin-right: -1.6rem;
		margin-left: -1.6rem;
	}
	
	// .container{
	// 	padding-right: 3.7rem;
	// 	padding-left: 3.7rem;
	// }
}