//!!BUTTON
button:focus-visible,
.btn:focus-visible{
	outline: none;
}

.btn-con{
	display: flex;
	align-items: center;
	margin: 0 -2.13rem;
	max-width: calc(100% + 2.13rem + 2.13rem);
	flex-wrap: wrap;

	@media (max-width: $md){
		margin: 0 -.2rem;
		max-width: calc(100% + .2rem + .2rem);
	}

	.arrow-link,
	.btn{
		margin: 0 2.13rem 2.13rem;

		@media (max-width: $md){
			margin: 0 .2rem .2rem;
		}

		&:first-of-type:last-of-type{
			margin-bottom: 0;
		}
	}

	&.btn-con-center{
		justify-content: center;
	}

	&.btn-con-left{
		justify-content: flex-start;
	}

	&.btn-con-right{
		justify-content: flex-end;
	}
	
}

.btn, %btn{
	display: inline-flex;
	align-items: center;
	justify-content: center;
	font-size: 1.3rem!important;
	font-size: clamp(13px , 1.3rem, 1.3rem)!important;
	line-height: 1.2;
	font-weight: 300;
	color: $white!important;
	text-decoration: none!important;
	font-family: $main-font;
	text-decoration: none;
	text-align: center;
	padding: 1.104rem 2.2rem;
	padding: 11.04px 22px;
	background-color: $black;
	border: .04rem solid $black;
	border-radius: 0;
	box-shadow: none;
	cursor: pointer;
	position: relative;
	transition: $time;
	z-index: 2;
	text-transform: uppercase;
	
	&:hover,
	&:focus-visible{
		outline: none;
		background-color: $blue;
		border-color: $blue;
		color: $white!important;

		&:after{
			max-width: 0%;
		}
	}

	&.disabled,
	&:disabled{
		opacity: .5;
		pointer-events: none;
	}
}

.btn{

	&.line{
		background-color: transparent;
		border-color: $white;
		color: $white!important;

		&:after{
			left: 0;
			right: auto;
			max-width: 0%;
			background-color: $white;
		}
		

		&:hover,
		&:focus-visible{
			background-color: transparent;
			border-color: $white;
			color: $blue!important;

			&:after{
				max-width: 100%;
			}
		}
	}

	&.big{
		
		@media (min-width: $md + 1){
			min-width: 25.9rem;
			padding: 2.2rem 2.2rem;
		}
	}

	&.small{
		
	}
}

