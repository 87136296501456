
//!!FORM
::-webkit-input-placeholder {
	@include font-size(10);
	font-size: clamp(10px , 1.0rem, 1.0rem);
	color: currentColor;
}
 
:-moz-placeholder { /* Firefox 18- */
	@include font-size(10);
	font-size: clamp(10px , 1.0rem, 1.0rem);
	color: currentColor;
}

::-moz-placeholder {  /* Firefox 19+ */
	@include font-size(10);
	font-size: clamp(10px , 1.0rem, 1.0rem);
	color: currentColor;
}

:-ms-input-placeholder {
	@include font-size(10);
	font-size: clamp(10px , 1.0rem, 1.0rem);
	color: currentColor;
}

form{
	margin: 0 auto;
	width: auto;
	display: block;
	margin-bottom: 0;

	&:after{
		visibility:hidden;
		display:block;
		font-size:0;
		content:" ";
		clear:both;
		height:0;
	}

	* html &{
		height:1%;
	}
}

$input-bottom-margin: 20.5px;
$label-bottom-margin: 20px;

input,
input.input-text,
textarea,
.fake-select,
select{
	display: block;
	padding: 11px 0rem;
	@include font-size(10);
	font-size: clamp(10px , 1.0rem, 1.0rem);
	@include line-height(10,10);
	font-weight: 300;
	letter-spacing: .3em;
	color: $colour;
	font-family: $main-font;
	width: 100%;
	max-width: 100%;
	margin-bottom: 0;
	border: .04rem solid $stone;
	border-top: none;
	border-left: none;
	border-right: none;
	background-color: transparent;
	border-radius: 0;
	-webkit-appearance: none;
	box-shadow: none;
}

label + .wpcf7-form-control-wrap:has(textarea){
	margin-top: 16px;
}

textarea{
	height: 80px;
	min-height: 80px;

	@media (max-width: $xl){
		
		@media (min-width: 1024px + 1){
			height: 36px;
			min-height: 36px;
		}
	}
}

input:focus-visible,
input.input-text:focus-visible,
textarea:focus-visible,
select:focus-visible{
	outline: none;
}

input:focus-visible,
input.input-text:focus-visible,
textarea:focus-visible,
select:focus-visible{
	outline: none;
	transition: color 0.5s ease, background-color 0.5s ease;
}

//
//!!SELECT START
//
.fake-select,
select{
	padding-right: 10px;
	appearance: none;
	background-image: url(../images/select-icon.svg);
	background-repeat: no-repeat;
	background-position: 100% 50%;
	background-size: 9px auto;
	line-height: calc(36px - .04rem);
	height: 36px;
	overflow: hidden;
	padding-top: 0;
	padding-bottom: 0;
	background-position: 100% 50%;

	&::-ms-expand {
		display: none;
	}

	option{
		padding-top: .05rem;
		padding-bottom: .05rem;
		padding: .1rem;
		color: $black;
		text-transform: uppercase;
	}
}
//
//!!SELECT END
//

legend,
label{
	display: block;
	@include font-size(10);
	font-size: clamp(10px , 1.0rem, 1.0rem);
	@include line-height(10,16);
	font-family: $main-font;
	margin-bottom: $label-bottom-margin;
	transition: $time;
	font-weight: 300;
	color: $colour;
	width: 100%;
	text-transform: uppercase;
	letter-spacing: .3em;

	.gfield_required{
		
		&:after{
			content: '*';
			color: inherit;
			margin-left: .2em;
		}
		
		span{
			display: none;
		}
	}
}

*[type="submit"],
.submit-con [type="submit"]{
	@extend %btn;
	width: 100%;
	min-width: 0;
	padding-left: 2.6rem;
	padding-right: 2.6rem;
	font-size: 1rem!important;
	font-size: clamp(10px , 1.0rem, 1.0rem)!important;
	background-color: transparent;
	padding: 11px 0rem;
	border-top: none;
	border-right: none;
	border-left: none;
	color: $colour!important;
	border-color: $stone;
	letter-spacing: .3em;
	justify-content: flex-start;

	@media (min-width: $md + 1){
		width: calc(50% - 1.5rem);
	}
	&:hover {
		color: $colour!important;
		border-color: $stone!important;
		background: transparent!important;
	}
}

.submit-con{
	display: flex;
}

//
//!!RADIO & CHECKBOX START
//

.input-radio,
.input-checkbox{
	position: relative;
	@include inline;
	margin-bottom: 5px;

	&:before{
		content: '';
		display: block;
		position: absolute;
	}
}

input[type="radio"],
input[type="checkbox"]{
	display: none;

	& + span,
	& + label{
		padding-left: 26px!important;
		position: relative;
		cursor: pointer;
		@include font-size(10);
		font-size: clamp(10px , 1.0rem, 1.0rem);
		line-height: 10px;
		@include inline;
		margin-top: 0;
		margin-bottom: 1px;
		font-style: normal;
		color: $colour;
		font-weight: 400;
		text-transform: none;
		text-align: left!important;
		width: 100%;
		margin-right: 11px;
		
		a{
			font-weight: 700;

			&:hover,
			&:focus-visible{
				color: $blue;
			}
		}

		&:before{
			content: '';
			position: absolute;
			width: 10px;
			height: 10px;
			left: 10px;
			top: 0rem;
			border-radius: 0rem;
			border: .04rem solid $grey-dark;
			background-color: $grey-dark;
		}

		&:after{
			content: '\d7';
			position: absolute;
			width: 7px;
			height: 7px;
			left: 11px;
			top: 0;
			border-radius: 0rem;
			color: #fff;
			opacity: 0;
			transition: $time;
			font-size: 12px;
		}
	}

	&:checked + label:after,
	&:checked + span:after,
	& + label.checked:after{
		opacity: 1;
	}
}

input[type="radio"]{

	& + span,
	& + label{

		&:before,
		&:after{
			border-radius: 50%;
		}
	}
}

//
//!!RADIO & CHECKBOX END
//


//
//!!GRAVITY START
//

.gform_wrapper{
	position: relative;

	.gform_submission_error{
		display: none;
	}

	fieldset{
		padding: 0;
		margin: 0;
		border: none;
	}

	.gform_fields{
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		padding: 1px 0 1px;
	
		.gfield,
		.gfield--width-half,
		.gfield--width-third{
			position: relative;
			display: flex;
			flex-wrap: wrap;
			align-items: flex-start;
			margin-bottom: $input-bottom-margin;
		}

		.spacer {
			display: none;
		}

		.instruction{
			display: none;
		}

		.gfield{
			width: 100%;
			margin-bottom: 15px;

			@media (max-width:$md) {
				margin-bottom: 50px;
			}

			&.spacer{
				margin-bottom: 0;
			}

			&.gfield_visibility_hidden{
				overflow: hidden;
				max-height: 0;
				opacity: 0;
				margin-bottom: 0;
			}
		}

		.gfield--width-half{
			width: calc(50% - (#{$grid-gutter-widthWO/10+rem}/2));

			@media (min-width: $md + 1){
				width: calc(50% - 1.5rem);
			}

			@media (max-width: $md){
				width: 100%;
			}
		}

		.gfield--width-third{
			width: 32%;
			width: calc(33.333% - (#{$grid-gutter-widthWO/10+rem}/2));

			@media (min-width: $md + 1){
				width: calc(33.333% - 1.5rem);
			}

			@media (max-width: $md){
				width: calc(50% - (#{$grid-gutter-widthWO/10+rem}/2));
				width: calc(50% - 1.5rem);

				+ .small + .small{
					width: 100%;
				}
			}

			@media (max-width: $sm){
				width: 100%;
			}
		}

		.gfield{

			&.hidden_label{
				
				legend,
				label{
					display: none;
				}
			}

			&.gfield--type-select{
				margin-bottom: 2.7rem;
			}

			.ginput_container{
				width: 100%;
			}

			&.gfield--type-consent{
				border: none;
				padding: 0;
				margin-left: 0;
				margin-right: 0;

				.gfield_label{
					display: none;
				}
			}

			.gfield_checkbox{
				display: flex;
				align-items: center;
				flex-wrap: wrap;

				input[type="checkbox"] + label{
					width: auto;
				}
			}
		}
	}

	.gform_fileupload_rules{
		display: none;
	}

	.gform_footer{
		margin-top: .0rem;
	}

	.ginput_preview_list{
		@include font-size(14);
		font-family: $main-font;
		position: absolute;
		top: 100%;
		width: 100%;

		.gfield_fileupload_percent{
			margin-left: .4em;
		}

		.gform_delete_file{
			text-decoration: none;
			@include font-size(12);
			margin-left: 0em;
			line-height: 1.1;

			*{
				@include font-size(12);
			}
		}
	}

	.gfield_validation_message{
		@include font-size(8);
		font-size: clamp(8px , .8rem, .8rem);
		color: $red;
		font-family: $main-font;
		letter-spacing: .3em;
	}

	.gform_ajax_spinner{
		margin: 1rem auto 0;
	}

	.gform_submission_error{
		@include font-size(10);
		color: $red;
		font-weight: 400;
		font-family: $main-font;
	}
}

.gform_confirmation_message{
	font-weight: 400;
	@include font-size(28);
	font-family: $alt-font;
}

//
//!!GRAVITY END
//
.hidden-desktop {
	@media (min-width: 821px){
		display: none!important;
	}
}