//!!SCALE SETTINGS
$scale: true; //false, true
$vw-viewport: 1920; //If false does nothing. if true set this to the width of the design sent over

//!!GRID SETTINGS
$grid-gutter-widthWO: 27 !default; //most common gutter width

//!!PAGE WIDTH
$base-widthWO:		1745; //most common container width

//!!COLOURS
$red:				#A2002F;

$green:				#97AD9C;

$blue:				#9CB0C1;

$orange:			#BC8752;

$stone:				#B6B09E;

$cream:				#F8F7F3;

$grey:				#FCFCFA;
$grey-dark:			#1C1C1C;

$white:				#FCFCFA;
$black:				#000000;

$colour: 			$grey-dark;
$main-bg-colour:	$white;

//!!FONTS
@font-face {
	font-family: 'General Sans';
	src: url('../fonts/GeneralSans-Light.woff2') format('woff2'),
		url('../fonts/GeneralSans-Light.woff') format('woff'),
		url('../fonts/GeneralSans-Light.ttf') format('truetype');
	font-weight: 300;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'General Sans';
	src: url('../fonts/GeneralSans-Regular.woff2') format('woff2'),
		url('../fonts/GeneralSans-Regular.woff') format('woff'),
		url('../fonts/GeneralSans-Regular.ttf') format('truetype');
	font-weight: 400;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Butler';
	src: url('../fonts/Butler-Light.woff2') format('woff2'),
		url('../fonts/Butler-Light.woff') format('woff'),
		url('../fonts/Butler-Light.ttf') format('truetype');
	font-weight: 300;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Butler';
	src: url('../fonts/Butler.woff2') format('woff2'),
		url('../fonts/Butler.woff') format('woff'),
		url('../fonts/Butler.ttf') format('truetype');
	font-weight: 400;
	font-style: normal;
	font-display: swap;
}

$font-general:		'General Sans', sans-serif;
//light - 300
//regular - 400

$font-butler:		'Butler', serif;
//light - 300
//regular - 400

$main-font:			$font-general;
$alt-font:			$font-butler;

//!!SITE TRANSITIONS
$time:				.3s;

//BREAK POINTS
$xl:				$base-widthWO - 1px ; //$base-width + - 1px
$xl:				1440px - 1px ; //$base-width + - 1px
$lg:				992px - 1px; //992px - 1px
$md:				821px - 1px; //769px - 1px
$sm:				576px - 1px; //576px - 1px

//xl: $base-width
//lg: 992px
//md: 768px
//sm: 576px