 footer{
	position: relative;

	.home &{
		
		@media (min-width: 1024px + 1){
			display: none;
		}

		@media (max-width: 1366px) and (min-width: 1024px) and (min-height: 767px) and (min-aspect-ratio: 4/3) and (hover: none){
			display: block;
		}
	}

	.footer-top-outer{
		position: relative;
		background-color: $grey-dark;
		padding-top: 4rem;
		padding-bottom: 6.4rem;

		@media (max-width: $xl){
			padding-bottom: 5.5rem;
		}

		@media (max-width: 1366px) and (min-width: 1024px) and (min-height: 767px) and (min-aspect-ratio: 4/3) and (hover: none){
			padding-top: 9rem;
			padding-bottom: 5.5rem;
		}

		@media (max-width: 1024px){
			padding-top: 9rem;
			padding-bottom: 5.5rem;
		}

		@media (max-width: $md){
			padding-top: 6.3rem;
			padding-bottom: 6.2rem;
		}

		*{
			color: white
		}

		.row{
			align-items: flex-end;
		}

		.col-md-10{
			
			@media (min-width: $md + 1){
				flex: 0 0 61.5%;
				max-width: 61.5%;
				flex: 0 0 58.5%;
				max-width: 58.5%;
			}

			@media (min-width: 1024px + 1){
				flex: 0 0 68.7%;
				max-width: 68.7%;
			}

			@media (min-width: $xl + 1){
				flex: 0 0 77.55%;
				max-width: 77.55%;
			}
		}

		.col-md-2{

			@media (min-width: $md + 1){
				flex: 0 0 19.25%;
				max-width: 19.25%;
				flex: 0 0 22.45%;
				max-width: 22.45%;
			}

			@media (min-width: 1024px + 1){
				flex: 0 0 31.3%;
				max-width: 31.3%;
			}

			@media (min-width: $xl + 1){
				flex: 0 0 22.45%;
				max-width: 22.45%;
			}

			@media (max-width: $md){
				flex: 0 0 100%;
				max-width: 100%;
			}
		}

		.container{
			max-width: calc(100% - 3rem);

			@media (max-width: $md){
				max-width: 100%;
			}
		}

		.contact{

			@media (max-width: $md){
				margin-bottom: 1.68rem;
			}

			address{
				@include font-size(12);
				font-size: clamp(12px , 1.2rem, 1.2rem);
				@include line-height(12,16);
				margin-bottom: 1.1em;

				@media (max-width: $xl){
					margin-bottom: 1.4em;
				}
			}

			a{
				@include font-size(12);
				font-size: clamp(12px , 1.2rem, 1.2rem);
				@include line-height(12,16);
				text-decoration: none;
				position: relative;

				&:hover,
				&:focus-visible{
					
					&:after{
						max-width: 100%;
					}
				}

				&:after{
					content: '';
					position: absolute;
					bottom: 0;
					left: 0;
					width: 100%;
					max-width: 0;
					height: .04rem;
					background-color: currentColor;
					transition: $time;
				}
			}
		}
		
		.footer-menu{
			@include font-size(9);
			font-size: clamp(9px , .9rem, .9rem);
			@include line-height(9,12);
			margin: 0;

			li{
				@include font-size(9);
				font-size: clamp(9px , .9rem, .9rem);
				@include line-height(9,12);
				margin-bottom: .2rem;
			}

			a{
				@include font-size(9);
				font-size: clamp(9px , .9rem, .9rem);
				@include line-height(9,12);
				text-decoration: none;
				color: $white;
				font-family: $main-font;
				font-weight: 300;
				letter-spacing: .3em;
				text-transform: uppercase;
				position: relative;
				
	
				&:hover,
				&:focus-visible{
					color: $white;
					text-decoration: none;

					&:after{
						max-width: 100%;
					}
				}

				&:after{
					content: '';
					position: absolute;
					bottom: 0;
					left: 0;
					width: 100%;
					max-width: 0;
					height: .04rem;
					background-color: currentColor;
					transition: $time;
				}
			}
		}

		@media (max-width: $xl){
			padding-bottom: 3.2rem;
		}

		@media (max-width: 1366px) and (min-width: 1024px) and (min-height: 767px) and (min-aspect-ratio: 4/3) and (hover: none){
			padding-bottom: 4rem;
		}

		@media (max-width: 1024px){
			padding-bottom: 4rem;
		}

		@media (max-width: $md){
			padding-bottom: 4.3rem;
		}

		.container{
			max-width: calc(100% - 3rem);

			@media (max-width: $md){
				max-width: 100%;
			}
		}

		*{
			color: white
		}

		.text,
		.small{
			margin-bottom: 3.2rem;
		}

		.text{

			@media (max-width: $md){
				display: none;
			}
		}

		h2{
			width: 34rem;
			margin-bottom: 0;
			font-size: clamp(36px , 3.6rem, 3.6rem);

			@media (max-width: $xl){
				width: 47rem;
			}

			@media (max-width: 1366px) and (min-width: 1024px) and (min-height: 767px) and (min-aspect-ratio: 4/3) and (hover: none){
				width: 61rem;
			}

			@media (max-width: 1024px){
				width: 61rem;
			}

			@media (max-width: $md){
				display: none;
			}
		}

		.row{
			align-items: flex-end;
		}

		.registered,
		.copy{
			@include font-size(9);
			font-size: clamp(9px , .9rem, .9rem);
			@include line-height(9,12);
			color: white;

			p {
				@include font-size(9);
				font-size: clamp(9px , .9rem, .9rem);
				@include line-height(9,12);
				color: white;
				margin-bottom: 0;
			}
		}
		
		.registered{

			@media (max-width: $xl){
				width: 60rem;
			}

			@media (max-width: 1366px) and (min-width: 1024px) and (min-height: 767px) and (min-aspect-ratio: 4/3) and (hover: none){
				width: 77rem;
			}

			@media (max-width: 1024px){
				width: 77rem;
			}
		}

		.copy{
			text-transform: uppercase;
			margin-bottom: .5em;
			letter-spacing: .3em;

			@media (max-width: $md){
				margin-top: 1.3rem;
			}
		}

		.col-md-8{

			@media (max-width: 1366px) and (min-width: 1024px) and (min-height: 767px) and (min-aspect-ratio: 4/3) and (hover: none){
				flex: 0 0 61.5%;
				max-width: 61.5%;
			}

			@media (max-width: 1024px){
				flex: 0 0 61.5%;
				max-width: 61.5%;
			}

			@media (max-width: $md){
				margin-top: 0;
				order: 2;
				flex: 0 0 100%;
				max-width: 100%;
			}
		}

		.col-md-4{
			display: flex;
			justify-content: flex-end;

			@media (max-width: 1366px) and (min-width: 1024px) and (min-height: 767px) and (min-aspect-ratio: 4/3) and (hover: none){
				flex: 0 0 38.5%;
				max-width: 38.5%;
			}

			@media (max-width: 1024px){
				flex: 0 0 38.5%;
				max-width: 38.5%;
			}

			@media (max-width: $md){
				flex: 0 0 100%;
				max-width: 100%;
				justify-content: flex-start;
			}
		}

		#footer-logo{
			display: block;
			height: auto;
			margin: 0;
			z-index: 1;
			transition: 0s;
			display: flex;
			width: 281px;

			@media (min-width: $md + 1){
				margin-bottom: 2.4rem;
				margin-right: 1.8rem;
			}

			@media (min-width: $xl + 1){
				margin-bottom: .4rem;
				margin-right: .3rem;
			}

			@media (max-width: $md){
				width: 5.105rem;
			}

			@media (max-width: 1366px) and (min-width: 1024px) and (min-height: 767px) and (min-aspect-ratio: 4/3) and (hover: none){
				margin-bottom: .7rem;
				margin-right: 4.6rem;
			}


			@media (max-width: 1024px){
				margin-bottom: .7rem;
				margin-right: 4.6rem;
			}

			@media (max-width: $md){
				width: 28.2rem;
			}
		
			img,
			svg{
				width: 100%;

				*{
					fill: white;
				}
			}
		}
	}
}