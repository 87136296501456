.pagination.pagination.pagination{
	margin-bottom: 0;
	vertical-align: middle;
	display: flex;
	justify-content: center;
	align-items: center;

	.pagination-mid{
		display: flex;
		justify-content: center;
		align-items: center;
	}

	div{
		text-align: center;
		margin: 0 .8rem;
		position: relative;
		z-index: 1;
		@include font-size(12);

		&:not(.prev):not(.next){

			&.active{
				pointer-events: none;

				a{
					color: $colour;
					text-decoration: none;
					font-weight: 400;
				}
			}

			span,
			a{
				display: block;
				color: $colour;
				font-weight: 300;
				padding: 0;
				text-decoration: none;
				width: auto;
				height: auto;
				border-radius: 0;
				text-align: center;
				font-family: $main-font;

				&:hover,
				&:focus-visible{
					color: currentColor;
					
					&:after{
						max-width: 100%;
					}
				}

				&:after{
					content: '';
					position: absolute;
					bottom: 0;
					left: 0;
					width: 100%;
					max-width: 0;
					height: .04rem;
					background-color: currentColor;
					transition: $time;
				}
			}

			&.ellipse{
				font-weight: 300;
			}
		}

		&.next,
		&.prev{

			a{
				display: flex;
				justify-content: center;
				align-items: center;
				text-decoration: none;
				height: auto;
				color: $colour;
				font-weight: 300;
				text-transform: uppercase;
				letter-spacing: .3em;

				&:hover,
				&:focus-visible{
					color: currentColor;
					
					&:after{
						max-width: 100%;
					}
				}

				&:after{
					content: '';
					position: absolute;
					bottom: 0;
					left: 0;
					width: 100%;
					max-width: 0;
					height: .04rem;
					background-color: currentColor;
					transition: $time;
				}
			}
		}
	}
}