//!!MENU
header[role="banner"]{
	z-index: 50;
	width: 100%;
	transform: translateZ(0);
	perspective: 999999px;
	position: fixed;
	top: 0;
	left: 0;
	transition: $time*2;
	background-color: transparent;

	.menu-open &{
		background-color: transparent !important;
	}
	
	.scroll-down &,
	.scroll-up &{
		background-color: white;
	}

	.home &{

		@media (min-width: 1024px + 1){
			display: none;
		}

		@media (max-width: 1366px) and (min-width: 1024px) and (min-height: 767px) and (min-aspect-ratio: 4/3) and (hover: none){
			display: block;
		}
	}
}



	
	

@media (min-width: $md + 1){

	html:has(section.property-hero-section:first-of-type) body:not(.scroll-up):not(.scroll-down) header{

		#site-logo{
	
			svg{
		
				*{
					fill: $white;
				}
			}
		}
	}
	
	html:not(.menu-open):has(section.property-hero-section:first-of-type) body:not(.scroll-up):not(.scroll-down) header{
	
		#burger{
			color: $white;
		}

		@media (max-width: 1366px) and (min-width: 1024px) and (min-height: 767px) and (min-aspect-ratio: 4/3) and (hover: none){
			#site-logo{
	
				svg{
			
					*{
						fill: $white;
					}
				}
			}
		}
	
		@media (max-width: 1024px){
			
			#site-logo{
	
				svg{
			
					*{
						fill: $white;
					}
				}
			}
		}
	}
}

.menu-open.menu-open.menu-open.menu-open.menu-open.menu-open{

	@media (max-width: 1366px) and (min-width: 1024px) and (min-height: 767px) and (min-aspect-ratio: 4/3) and (hover: none){
		#site-logo{

			img,
			svg{
				width: 100%;

				*{
					fill: $grey-dark;
				}
			}
		}
	}
	
	@media (max-width: 1024px){
		
		#site-logo{

			img,
			svg{
				width: 100%;

				*{
					fill: $grey-dark;
				}
			}
		}
	}
}

@media (max-width: 1366px) and (min-width: 1024px) and (min-height: 767px) and (min-aspect-ratio: 4/3) and (hover: none){

	html:has(section.hero-section:first-of-type) body:not(.scroll-up):not(.scroll-down) header{

		#site-logo{
	
			svg{
		
				*{
					fill: $white;
				}
			}
		}
	}
	
	html:not(.menu-open):has(section.hero-section:first-of-type) body:not(.scroll-up):not(.scroll-down) header{
	
		#burger{
			color: $white;
		}
	
		@media (max-width: 1024px){
			
			#site-logo{
	
				svg{
			
					*{
						fill: $white;
					}
				}
			}
		}
	}

	html:not(.menu-open) body.home.home.home.home.home.home.home.home.home:not(.scroll-up):not(.scroll-down) header{

		#site-logo{
	
			svg{
		
				*{
					fill: $white;
				}
			}
		}
	}

	html:not(.menu-open) body.home.home.home.home.home.home.home.home.home:not(.scroll-up):not(.scroll-down) header{
	
		#burger{
			color: $white;
		}
	
		@media (max-width: 1024px){
			
			#site-logo{
	
				svg{
			
					*{
						fill: $white;
					}
				}
			}
		}
	}
}

@media (max-width: 1024px){

	html:has(section.hero-section:first-of-type) body:not(.scroll-up):not(.scroll-down) header{

		#site-logo{
	
			svg{
		
				*{
					fill: $white;
				}
			}
		}
	}
	
	html:not(.menu-open):has(section.hero-section:first-of-type) body:not(.scroll-up):not(.scroll-down) header{
	
		#burger{
			color: $white;
		}
	
		@media (max-width: 1024px){
			
			#site-logo{
	
				svg{
			
					*{
						fill: $white;
					}
				}
			}
		}
	}
}

#site-logo{
	display: block;
	height: auto;
	margin: 0;
	z-index: 1;
	transition: 0s;
	display: flex;
	width: clamp(226px, 27.8rem, 27.8rem);

	@media (max-width: $md){
		width: 5.105rem;
	}

	@media (max-width: 1366px) and (min-width: 1024px) and (min-height: 767px) and (min-aspect-ratio: 4/3) and (hover: none){
		width: 300px;
	}

	@media (max-width: 1024px){
		width: 300px;
	}

	@media (max-width: $md){
		width: 280px;
	}

	@media (max-width: $sm){
		width: 178px;
	}

	img,
	svg{
		width: 100%;

		*{
			fill: $grey-dark;
		}
	}
}

.menu-top-outer{
	transition: $time;
	display: flex;
	justify-content: flex-end;
	flex-wrap: wrap;

	.container{

		@media (min-width: $md + 1){
			max-width: calc(100% - 3rem);
		}
		
	}
	
	.col-12{
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding-top: clamp(18px, 1.9rem, 1.9rem);
		padding-bottom: clamp(18px, 1.9rem, 1.9rem);

		@media (max-width: 1366px) and (min-width: 1024px) and (min-height: 767px) and (min-aspect-ratio: 4/3) and (hover: none){
			padding-top: 14px;
			padding-bottom: 14px;
		}

		@media (max-width: 1024px){
			padding-top: 14px;
			padding-bottom: 14px;
		}

		@media (max-width: $md){
			padding-top: 1.3rem;
			padding-bottom: 1.3rem;
		}

		.right{
			flex-grow: 1;
			display: flex;
			align-items: center;
			justify-content: flex-end;
			padding-top: 3rem;

			@media (max-width: $md){
				padding-top: 2rem;
				padding-bottom: 2rem;
			}
		}
	}

	.links{
		margin-left: 2.4rem;

		@media (max-width: $md){
			margin-left: 2.7rem;
		}
	}
	
	.tel,
	.social{
		color: white;
		text-decoration: none;

		&:hover,
		&:focus-visible{
			color: $colour;
		}
	}

	.tel{
		margin-right: 2.1rem;
		@include font-size(16);
		font-weight: 900;

		@media (max-width: $md){
			margin-right: 0;
		}

		@media (max-width: $sm){
			@include font-size(0);
		}

		i{
			@include font-size(20);
			margin-right: 1.4rem;

			@media (max-width: $sm){
				margin-right: 0;
			}
		}
	}

	.social{
		@include font-size(22);
		margin-left: 2.7rem;
	}
}

.mob-only.mob-only{

	@media (min-width: 1300px + 1 ){
		display: none;
	}

	.social-links{
		justify-content: center;
	}
}

@import 'burger';

#mainMenu{
	display: flex;
	align-items: center;
	flex-wrap: wrap;

	@media (max-width: $md){
		margin: 0 auto;
		position: fixed;
		background-color: $blue;
		top: 10.85rem;
		left: 0;
		width: 100%;
		max-height: 0;
		transition: $time;
		padding: 0rem 1.6rem;
		flex-direction: column;
		flex-wrap: nowrap;
		display: flex;
		overflow: hidden;

		.menu-open &{
			max-height: calc(100vh - 10.85rem);
			overflow: auto;
		}
	}
}

@media (min-width: $md + 1){

	#mainMenu .main-menu .current-menu-ancestor:not(.menu-item-object-custom),
	#mainMenu .main-menu .current-page-ancestor:not(.menu-item-object-custom),
	#mainMenu .main-menu .current-menu-parent:not(.menu-item-object-custom),
	#mainMenu .main-menu .current_page_parent:not(.menu-item-object-custom),
	#mainMenu .main-menu .current-menu-item:not(.menu-item-object-custom){

		> a{
			color: $white;

			&:after{
				max-width: 100%;
			}
		}
	}
}

ul.main-menu.main-menu{
	margin: 0 0;
	padding: 0;
	@include font-size(0);

	@media (min-width: $md + 1){
		
		&:has(a:hover){
			
			li a:not(:hover){
				color: rgba($colour, .51);
			}
		}
	}

	@media (max-width: $md){
		padding-top: 18.4rem;

		&:after{
			

			@media (max-width: $md){
				content: '';
				position: absolute;
				transform: translate(-50%, -50%);
				width: 23.474rem;
				aspect-ratio: 234.74/269.81;
				z-index: 2;
				background-image: url(../images/side-logo.svg);
				background-repeat: no-repeat;
				background-size: 100% auto;
				left: 6.2rem;
				top: 12.3rem;
				width: 9.3rem;
			}
		}
	}
	
	li{
		@include font-size(0);
		line-height: 1;
		position: static;
		display: flex;
		text-align: left;
		
		@media (min-width: $md + 1){
			margin: 0 0 .4rem;

			&:hover,
			&:focus-visible{
				
				> a{
					color: $colour;
				}
			}
		}
	
		@media (min-width: $xl + 1){
			margin: 0 0 .55rem;
		}

		@media (max-width: 1366px) and (min-width: 1024px) and (min-height: 767px) and (min-aspect-ratio: 4/3) and (hover: none){
			margin-bottom: .5rem;
		}

		@media (max-width: 1024px){
			margin-bottom: .5rem;
		}

		// &:last-of-type{
		// 	margin-right: 0;
		// }

		&.no-link{

			> a{
				pointer-events: none;
			}
		}

		&.menu-item-has-children{
			flex-wrap: wrap;

			.sub-menu{
				
			}
		}

		> a{
			@include font-size(24);
			line-height: 1.2;
			width: auto;
			color: $colour;
			text-decoration: none!important;
			font-weight: 400;
			position: relative;
			font-family: $alt-font;
			z-index: 2;
			border-radius: 0rem;

			@media (max-width: $xl){
				@include font-size(21.1);
			}

			@media (max-width: 1366px) and (min-width: 1024px) and (min-height: 767px) and (min-aspect-ratio: 4/3) and (hover: none){
				font-size: 15px;
			}
			
			@media (max-width: 1024px){
				font-size: 15px;
			}

			@media (max-width: $md){
				@include font-size(24);
			}


			&:hover,
			&:focus-visible{
				color: $colour;

				&:after{
					max-width: 100%;
				}
			}
		}
	}
}

#burger {
	span {
		@media (max-width: $sm + 1){
			font-size: 12px;
		}
	}
}